import i18next from "i18next";
import browser from './browser';
import lang from "./lang";
import string from "./string";

class I18n {
    #fallback = 'en';
    async init(config) {
        this.props = {...config};
        const lng = config.language || browser.language;
        const debug = config.debug;
        config.fallback && (this.#fallback = config.fallback);
        const keySeparator = false; // to prevent period(.) conflict in message key. flat json does not require keySeparator.
        const nsSeparator = false; // to prevent semicolon(:) conflict in message key.
        await i18next.init({lng, debug, keySeparator, nsSeparator});
    }
    translate(text, params) {
        if(lang.isNullOrUndefined(text)) return '';
        const key = text.toLowerCase();
        const translation = i18next.t(key, text);
        return string.format(translation, params);
    }
    addTranslation(language, translation) {
        const namespace = "translation";
        const resources = {};
        for(const key in translation) {
            resources[key.toLowerCase()] = translation[key];
        }
        i18next.addResourceBundle(language, namespace, resources);
    }
    async changeLanguage(language) {
        await i18next.changeLanguage(language);
    }
    resolve(value, language = browser.language) {
        if(!lang.isObject(value)) return value;
        if(lang.isEmpty(value)) return null;
        const locale = browser.toLocale(language);
        if(value.hasOwnProperty(language)) {
            return value[language]
        } else if(value.hasOwnProperty(locale.language)) {
            return value[locale.language]
        } else if(value.hasOwnProperty(this.#fallback)) {
            return value[this.#fallback]
        } else {
            return Object.values(value)[0];
        }
    }
}

const i18n = new I18n();

export default i18n;