import React, {useRef, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Drawer, useTheme} from "@material-ui/core";
import Launchpad from "../../component/Launchpad";
import {useDispatch, useSelector} from "../../store";
import {selectApplets, selectUserInfo} from "../../reducer/iam/authReducer";
import { useHistory } from "react-router-dom";
import Button from "../../lib/appfuse-react/component/Button";
import Space from "../../lib/appfuse-react/component/Space";
import Typography from "@material-ui/core/Typography";
import SearchField from "../../component/SearchField";
import Expander from "../../lib/appfuse-react/component/Expander";
import Toolbar from "../../component/Toolbar";
import UserProfile from "./UserProfile";
import lang from "../../lib/appfuse-react/core/lang";
import {selectTitle} from "../../reducer/app/appReducer";
import i18n from "../../lib/appfuse-react/core/i18n";
import Box from "../../lib/appfuse-react/component/Box";
import env from "../../lib/appfuse-react/core/env";
import useRouter from "../../lib/appfuse-react/hook/useRouter";
import Image from "../../lib/appfuse-react/component/Image";
import authService from "../../service/iam/authService";

const initialContext = {
    workspace: undefined
}

const WorkbenchContext = React.createContext(initialContext);

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 100,
        backgroundColor: theme.brand.appbar?.backgroundColor,
        color: theme.brand.appbar?.color,
    },
    drawer: {
        flexShrink: 0
    },
    drawerPaper: {
        top: theme.spacing(8),
        height: "100%",
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    }
}));

function Workbench(props) {
    const theme = useTheme();
    const router = useRouter();
    const backgroundImage = router.resolve(theme.brand.backgroundImage);
    const classes = useStyles({backgroundImage});
    const history = useHistory();
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState(null);
    const [launcherOpen, setLauncherOpen] = useState(false);
    const [userProfileAnchorEl, setUserProfileAnchorEl] = useState(null);
    const title = useSelector(selectTitle);
    const userInfo = useSelector(selectUserInfo);
    const applets = useSelector(selectApplets);
    const responsive = env.responsive;
    const workspaceRef = useRef();
    const workspace = workspaceRef?.current;

    const handleToggleLauncher = () => setLauncherOpen(!launcherOpen);
    const handleCloseLauncher = () => setLauncherOpen(false);

    const handleLaunchpadClick = (event) => {
        const { path } = event;
        history.push(`/${path}`);
    };

    const handleToggleUserProfile = (event) => {
        setUserProfileAnchorEl(userProfileAnchorEl ? null : event.currentTarget);
    };

    const handleUserProfileClose = () => {
        setUserProfileAnchorEl(null);
    }

    const handleChangePassword = () => {

    }

    const handleLogout = () => {
        setUserProfileAnchorEl(null);
        authService.logout();
        router.reload();
    }

    const handleSearch = event => {
        const keyword = event.value;
        setKeyword(keyword);
    }

    window.document.title = i18n.translate(title);

    const settings = env.get('app');

    const stageInfo = env.appStage.toUpperCase() === 'PROD' ? null : env.appStage;
    const versionInfo = env.appVersion + `${stageInfo ? '@' + stageInfo: ''}`;
    return (
        <Box className="app-workbench" fullWidth fullHeight overflow="hidden" display="flex" flexDirection="column">
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Image value={theme.brand.logo} onClick={()=> history.push('/main')} height={46} title={versionInfo} />
                    <Space spacing={1} />
                    <Typography variant="h5" noWrap>
                        {i18n.translate(title)}
                        <Space spacing={1} />
                        {stageInfo ? <Typography variant="body2" display="inline">{versionInfo}</Typography> : null}
                    </Typography>
                    <Box display={lang.isFalse(settings.search) ? 'none' : 'initial'}>
                        <SearchField label="Search" value={keyword} onChange={handleSearch} />
                    </Box>
                    <Expander />
                    <Button icon="mail" variant="icon" badge={12}  color="inherit" />
                    <Button icon="notifications" variant="icon" badge={4}  color="inherit" />
                    <Button icon="account_circle" variant="icon"  color="inherit" onClick={handleToggleUserProfile} />
                    <Button icon="apps" variant="icon" onClick={handleToggleLauncher}  color="inherit" />
                </Toolbar>
            </AppBar>
            <Box fullWidth fullHeight backgroundImage={backgroundImage} overflow="auto">
                <Box ref={workspaceRef} fullHeight fullWidth overflow="hidden" minWidth={`${responsive.minWidth}px`}>
                    <WorkbenchContext.Provider value={{workspace}}>
                        {props.children}
                    </WorkbenchContext.Provider>
                </Box>
            </Box>
            <Drawer className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                    anchor="right"
                    open={launcherOpen}
                    onClose={handleCloseLauncher} >
                <Launchpad data={applets} onClick={handleLaunchpadClick} />
            </Drawer>
            <UserProfile anchorEl={userProfileAnchorEl} userInfo={userInfo}
                         open={lang.isNotNullOrUndefined(userProfileAnchorEl)}
                         onClose={handleUserProfileClose} onChangePassword={handleChangePassword} onLogout={handleLogout}/>
        </Box>
    )
}

const withWorkbench = WrappedComponent => (props) => {
    return (
        <Workbench>
            <WrappedComponent {...props} />
        </Workbench>
    );
};

export default Workbench;

export { withWorkbench, WorkbenchContext };