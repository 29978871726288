import React, {useEffect, useRef, useState} from "react";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {AppBar, Dialog, DialogContent, Divider, Grid, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "../../store";
import {
    selectApplets,
    selectInboxCounts,
    selectUserInfo,
    setInboxCounts,
    setUserInfo
} from "../../reducer/iam/authReducer";
import { useHistory } from "react-router-dom";
import Button from "../../lib/appfuse-react/component/Button";
import Space from "../../lib/appfuse-react/component/Space";
import Typography from "@material-ui/core/Typography";
import SearchField from "../../component/SearchField";
import Expander from "../../lib/appfuse-react/component/Expander";
import Toolbar from "../../component/Toolbar";
import UserProfile from "./UserProfile";
import lang from "../../lib/appfuse-react/core/lang";
import {selectTitle} from "../../reducer/app/appReducer";
import i18n from "../../lib/appfuse-react/core/i18n";
import Dock, {DockItem} from "../../lib/appfuse-react/component/Dock";
import Box from "../../lib/appfuse-react/component/Box";
import env from "../../lib/appfuse-react/core/env";
import useRouter from "../../lib/appfuse-react/hook/useRouter";
import Image from "../../lib/appfuse-react/component/Image";
import authService from "../../service/iam/authService";
import usePrompt from "../../lib/appfuse-react/hook/usePrompt";
import Form from "../../lib/appfuse-react/component/Form";
import MailDetail from "./MailDetail";

const DEFAULTICON = 'image/application/webpage.svg';

const initialContext = {
    workspace: undefined
}

const DesktopContext = React.createContext(initialContext);


const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 100,
        backgroundColor: theme.brand.appbar?.backgroundColor,
        color: theme.brand.appbar?.color,
    }
}));

function Desktop(props) {
    const prompt = usePrompt();
    const theme = useTheme();
    const router = useRouter();
    const backgroundImage = router.resolve(theme.brand.backgroundImage);
    const classes = useStyles({backgroundImage});
    const history = useHistory();
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState(null);
    const [userProfileAnchorEl, setUserProfileAnchorEl] = useState(null);
    const [mailDetailAnchorEl, setMailDetailAnchorEl] = useState(null);
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const title = useSelector(selectTitle);
    const userInfo = useSelector(selectUserInfo);
    const applets = useSelector(selectApplets);
    const inboxCounts = useSelector(selectInboxCounts);
    const responsive = env.responsive;
    const workspaceRef = useRef();
    const workspace = workspaceRef?.current;

    const [showChangePassword, setShowChangePassword] = useState(false);
    const location = props.children.props.location;
    const {search} = location;
    const urlParams = new URLSearchParams(search);
    const changePassword = urlParams.get("changePassword");
    if (lang.isNotEmpty(changePassword) && changePassword==="true" && lang.isFalse(showChangePassword)) {
        setChangePasswordOpen(true);
        setShowChangePassword(true);
    }

    useEffect(() => {
        (async () => {
            await getUserInfo();
        })();
    }, []);

    const getUserInfo = async () => {
        try {
            const userInfo = await authService.fetchUserInfo();
            const inboxCounts = await authService.fetchInboxCounts();
            dispatch(setUserInfo(userInfo));
            dispatch(setInboxCounts(inboxCounts));
        } catch (e) {
            prompt.alert(e, {title: 'Failed to fetch User Info.'}).then();
        }
    };

    const handleDockItemClick = (event) => {
        const { value } = event;
        history.push(`/${value}`);
    };

    const handleToggleUserProfile = (event) => {
        setUserProfileAnchorEl(userProfileAnchorEl ? null : event.currentTarget);
    };

    const handleToggleMailDetail = (event) => {
        setMailDetailAnchorEl(mailDetailAnchorEl ? null : event.currentTarget);
    }

    const handleUserProfileClose = () => {
        setUserProfileAnchorEl(null);
    }

    const handleMailDetailClose = () => {
        setMailDetailAnchorEl(null);
    }

    const handleChangePasswordOpen = () => setChangePasswordOpen(true);
    const handleChangePasswordClose = () => setChangePasswordOpen(false);
    const handleChangePassword = async (value) => {
        await authService.changePassword(value);
    };

    const handleLogout = () => {
        setUserProfileAnchorEl(null);
        setMailDetailAnchorEl(null);
        authService.logout();
        router.reload();
    }

    const handleSearch = event => {
        const keyword = event.value;
        setKeyword(keyword);
    }

    window.document.title = i18n.translate(title);

    const dockItems = applets.map(applet => {
        const { id, path, text = 'Unknown', icon = DEFAULTICON, locked, disabled, badge, description } = applet;

        return (
            <DockItem key={id} icon={icon} text={text} description={description} locked={locked} disabled={disabled} badge={badge} value={path}
                      onClick={handleDockItemClick}/>
        )
    });

    const settings = env.get('app');
    const stageInfo = env.appStage.toUpperCase() === 'PROD' ? null : env.appStage;
    const versionInfo = env.appVersion + `${stageInfo ? '@' + stageInfo: ''}`;
    const inboxCountsSum = Object.values(inboxCounts).reduce((sum, count) => sum + count, 0);

    return (
        <Box className="app-desktop" fullWidth fullHeight overflow="hidden" display="flex" flexDirection="column">
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Image value={theme.brand.logo} onClick={()=> history.push('/main')} height={46} title={versionInfo} />
                    <Space spacing={1} />
                    <Typography variant="h5" noWrap>
                        {i18n.translate(title)}
                        <Space spacing={1} />
                        {stageInfo ? <Typography variant="body2" display="inline">{versionInfo}</Typography> : null}
                    </Typography>
                    <Box display={lang.isFalse(settings.search) ? 'none' : 'initial'}>
                        <SearchField label="Search" value={keyword} onChange={handleSearch} />
                    </Box>
                    <Expander />
                    <Typography variant="subtitle1" noWrap>{`${i18n.translate('Welcome')} ${userInfo?.name || ''}`}</Typography>
                    <Space spacing={2} />
                    <Button icon="mail" variant="icon" badge={inboxCountsSum} onClick={handleToggleMailDetail}  color="inherit" />
                    <Button icon="account_circle" variant="icon"  color="inherit" onClick={handleToggleUserProfile} />
                </Toolbar>
            </AppBar>
            <Box fullHeight fullWidth backgroundImage={backgroundImage} display="flex" overflow="hidden">
                <Box fullHeight fullWidth flexGrow={1} overflow="auto">
                    <Box ref={workspaceRef} fullHeight fullWidth overflow="hidden" minWidth={`${responsive.minWidth}px`}>
                        <DesktopContext.Provider value={{workspace}}>
                            {props.children}
                        </DesktopContext.Provider>
                    </Box>
                </Box>
                <Dock translucent defaultExpanded>{dockItems}</Dock>
            </Box>
            <UserProfile anchorEl={userProfileAnchorEl} userInfo={userInfo}
                         open={lang.isNotNullOrUndefined(userProfileAnchorEl)}
                         onClose={handleUserProfileClose} onChangePassword={handleChangePasswordOpen} onLogout={handleLogout}/>
            <MailDetail anchorEl={mailDetailAnchorEl} open={lang.isNotNullOrUndefined(mailDetailAnchorEl)}
                        userInfo={userInfo} inboxCounts={inboxCounts} onClose={handleMailDetailClose} onChangePassword={handleChangePasswordOpen} onLogout={handleLogout}/>
            {changePasswordOpen && <ChangePassword open={changePasswordOpen} onClose={handleChangePasswordClose} onApply={handleChangePassword}/>}
        </Box>
    )
}

const withDesktop = WrappedComponent => (props) => {
    return (
        <Desktop>
            <WrappedComponent {...props} />
        </Desktop>
    );
};

function ChangePassword(props) {
    const prompt = usePrompt();
    const {open} = props;
    const [value, setValue] = useState({});

    const handleChange = (event) => {
        const {value} = event;
        setValue(value);
    };

    const handleOK = async () => {
        if(lang.isEmpty(value?.newPassword)) {
            prompt.confirm('Please enter the new password.');
            return;
        } else if(lang.isEmpty(value?.confirmNewPassword)) {
            prompt.confirm('Please enter the new password again.');
            return;
        }
        try {
            await props.onApply(value);
            prompt.success('Password changed successfully.');
            props.onClose();
        } catch (e) {
            prompt.alert(e, {title: 'Failed to change password.'});
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    return (
        <Dialog open={open} onClose={props.onClose} maxWidth='xs' fullWidth>
            <Box paddingTop={2}>
                <Typography align='center' variant='h6'>{i18n.translate('Change Password')}</Typography>
            </Box>
            <Divider variant='middle'/>
            <DialogContent>
                <Box fullHeight fullWidth overflow='auto' paddingTop={2} paddingBottom={5} paddingX={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Form value={value} onChange={handleChange} onSubmit={handleOK}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {/*新密碼*/}
                                        <TextField name='newPassword' label='Please enter the new password.' variant='outlined' fullWidth required type='password'/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*確認新密碼*/}
                                        <TextField name='confirmNewPassword' label='Enter the new password again.' variant='outlined' fullWidth required type='password'/>
                                    </Grid>
                                </Grid>

                            </Form>
                        </Grid>
                        <Grid item xs={6}>
                            <Button icon='save' text='OK' variant='contained' color='primary' fullWidth onClick={handleOK}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Button icon='close' text='Cancel' variant='contained' color='primary' fullWidth onClick={handleCancel}/>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default Desktop;

export { withDesktop, DesktopContext };