import { createSlice } from '@reduxjs/toolkit';
import logger from "../../lib/appfuse-react/core/logger";
import json from "../../lib/appfuse-react/core/json";

const LOGGER = logger.getLogger('registrationReducer');
const SLICE_NAME = 'registration';

const initialState = {
    form: {},
};

const slice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setRegistrationForm(state, action) {
            state.form = json.toJSON(action.payload);
        }
    }
});

export const { reducer } = slice;

export const {setRegistrationForm} = slice.actions;

export const selectRegistrationForm = state => json.fromJSON(state[SLICE_NAME].form);

const registrationReducer = reducer;

export default registrationReducer;
