import inboxes from "./inboxes";

const inboxService = [
    {
        matcher: 'inboxes',
        reply: config => {
            return [200, inboxes];
        }
    },
    {
        matcher: /inboxes\/(.*)/,
        reply: config => {
            const id = /inboxes\/(.*)/.exec(config.url)[1];
            const draft = inboxes.find(draft => draft.id === id);
            return [200, draft];
        }
    }
]

export default inboxService;