import lang from "../../lib/appfuse-react/core/lang";

class ValueChange {

    isEqualCmp(value, target) {
        return lang.isEqual(value, target);
    }

    isNotEqualCmp(value, target) {
        return !lang.isEqual(value, target);
    }

    isIncludeCmp(value, target) {
        return value && value.includes(target);
    }

    isNotIncludeCmp(value, target) {
        return !(value && value.includes(target));
    }

    isEqual(value, change, target) {
        return this.is(value, change, target, this.isEqualCmp);
    }

    isNotEqual(value, change, target) {
        return this.is(value, change, target, this.isNotEqualCmp);
    }

    isInclude(value, change, target) {
        return this.is(value, change, target, this.isIncludeCmp);
    }

    isNotInclude(value, change, target) {
        return this.is(value, change, target, this.isNotIncludeCmp);
    }

    is(value, change, target, cmp) {
        const CMP = cmp || this.isEqualCmp;

        if(lang.isNotUndefined(change)) return CMP(change, target);
        else return value && CMP(value, target);
    }

    getEffectiveValue(value, change) {
        if(lang.isNotUndefined(change)) return change;
        return value;
    }

    getValue(value, replace) {
        if(lang.isNotUndefined(value)) return value;
        return replace;
    }

}

const valueChange = new ValueChange();
export default valueChange;