const fieldManagement = {
    "sections": [
        {
            "subject": "irrigationWater",
            "questions": [
                {
                    "description": "是否有使用灌溉水",
                    "type": "scq",
                    "options": ["有", "不使用"],
                    "questions": [
                        {
                            "condition": "有",
                            "description": "水源來源",
                            "type": "mcq",
                            "options": ["井水", "河流、溪", "水塘泉水", "自來水", "水利會供水系統", {
                                "value": "其他",
                                "elaboration": "請說明:required"
                            }]
                        },
                        {
                            "condition": "有",
                            "description": "本場區是否會遭慣行田區的地面水流入？",
                            "type": "scq",
                            "options": ["是", "否"],
                            "questions": [
                                {
                                    "condition": "是",
                                    "description": "承上，哪一塊田區會遭慣行田區地面水流入？",
                                    "type": "mcq",
                                    "options": ["F01", "F02", "F03", "F04", "F05", "F06", "F07"]
                                }
                            ]
                        }
                    ]
                },
                {
                    "description": "其他有關灌溉水的描述",
                    "type": "oeq"
                }

            ]
        },
        {
            "subject": "soilAndFertilizerManagement",
            "questions":[
                {
                    "description": "有沒有自製資材?",
                    "type": "scq",
                    "options": ["有", "沒有"],
                    "questions":[
                        {
                            "condition": "有",
                            "description": "自製資材相關說明",
                            "type": "oeq",
                            "format": {
                                "multiple": true,
                                "fields": ["品項","材料", "材料來源", "製作方式"]
                            }
                        }
                    ]
                },
                {
                    "description": "有沒有外購資材?",
                    "type": "scq",
                    "options": ["有", "沒有"],
                    "questions":[
                        {
                            "condition": "有",
                            "description": "外購資材相關說明",
                            "type": "oeq",
                            "format": {
                                "multiple": true,
                                "fields": ["品牌或來源", "產品名稱", "施放方法及用量", {"name": "用途", "type": "控制病蟲草害|提供養分與土壤改良", "multiple": true, "placeholder":"複選"}],
                                "examples": [{
                                    "品牌或來源": "興農公司",
                                    "產品名稱": '蘇力菌',
                                    "施放方法及用量": "稀釋1000倍/一禮拜一次",
                                    "用途":["控制病蟲草害"]
                                }]
                            }
                        }
                    ]
                }
            ]
        },
        {
            "subject": "otherFieldManagement",
            "questions":[
                {
                    "description": "您是否有使用含有塑膠的保護性敷蓋物或防蟲網等資材？",
                    "type": "scq",
                    "options": ["有使用", "沒有使用"],
                    "questions":[
                        {
                            "condition":"有使用",
                            "description":"承上，該資材的成分為何?",
                            "type":"mcq",
                            "options": ["聚乙烯(PE)", {
                                "value": "其他",
                                "elaboration": "請說明:required"
                            }]
                        },
                        {
                            "condition":"有使用",
                            "description":"您使用後塑膠產品是否在用後完整地移去？",
                            "type": "scq",
                            "options": ["是", {
                                "value": "否",
                                "elaboration": "請說明:required"
                            }]
                        }
                    ]
                },
                {
                    "description": "是否有使用焚燒雜草或焚毀作物殘體來控制病蟲草害？",
                    "type": "scq",
                    "options": ["是", "否"],
                }
            ]
        }
    ]
}


export default fieldManagement;