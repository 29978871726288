import {List, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Icon from "../Icon";
import React, {useState} from 'react';
import DockItem from "./DockItem";
import clsx from "clsx";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        position: 'relative'
    },
    list: {
        maxWidth: '4.5rem',
        minWidth: '4.5rem',
        height: '100%',
        overflow: 'auto'
    },
    expanded: {
        maxWidth: '14rem',
        minWidth: '12rem',
    },
    expander: {
        position: 'absolute',
        right: `${theme.spacing(1) /4}px`,
        top: `${theme.spacing(1)/4}px`
    },
    translucent: {
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    }
}));


function Dock(props) {
    const { translucent, children } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = useState(props.defaultExpanded);
    const icon = expanded ? 'remove_circle_outline' : 'add_circle_outline';
    const className = clsx(classes.list, expanded && classes.expanded);
    const handleExpanderClick = () => setExpanded(!expanded);
    return (
        <Paper className={clsx(classes.root, translucent && classes.translucent)} square>
            <List component="nav" className={className}>{children}</List>
            <Icon className={classes.expander} size="small" color="primary" onClick={handleExpanderClick}>{icon}</Icon>
        </Paper>

    )
}

Dock.propTypes = {
    translucent: PropTypes.bool,
    defaultExpanded: PropTypes.bool
}

Dock.defaultProps = {
    translucent: false,
    defaultExpanded: false
}

export default Dock;

export {DockItem};