import products from "./products";

for(const product of products) {
    product.image = `/image/product/${product.id}.jpg`;
    if(product.id==='Yellow Green Christmas Gift Box') {
        product.attachment = [
            `/image/product/${product.id}/c4a3def4-d5bf-4ede-aaba-a1f974ce95f0.png`,
            `/image/product/${product.id}/f9a4c670-a548-11ea-bb37-0242ac130002.png`
        ];
    }

}

const productService = [{
    matcher: 'products',
    reply: config => {
        return [200, products];
    }
}, {
    matcher: /products\/(.*)/,
    reply: config => {
        const id = /products\/(.*)/.exec(config.url)[1];
        const product = products.find(product => product.id === id);
        return [200, product];
    }
}]

export default productService;