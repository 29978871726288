import {createSlice} from "@reduxjs/toolkit";
import {json} from "../../lib/appfuse-react/core/json";

const name = 'inboxProto';

const initialState = {
    data: [],
    selection: [],
    pageNumber: 0,
    pageSize: 10,
    drafts: {},
}

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setData(state, action) {
            state.data = json.toJSON(action.payload);
        },
        setSelection(state, action) {
            state.selection = action.payload;
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setDrafts(state, action) {
            state.drafts = json.toJSON(action.payload);
        },
    }
})

export const { setData, setSelection, setPageNumber, setPageSize, setDrafts } = slice.actions;

export const selectData = state => json.fromJSON(state[name].data);
export const selectSelection = state => state[name].selection;
export const selectPageNumber = state => state[name].pageNumber;
export const selectPageSize = state => state[name].pageSize;
export const selectDrafts = state => json.fromJSON(state[name].drafts);
export const selectDraft = (key) => (state) => selectDrafts(state)[key];

export const setDraft = (key, draft) => (dispatch, getState) => {
    const drafts = selectDrafts(getState());
    drafts[key] = draft;
    dispatch(setDrafts(drafts));
}

export const removeDraft = key => (dispatch, getState) => {
    const drafts = selectDrafts(getState());
    delete drafts[key];
    dispatch(setDrafts(drafts));
}

const inboxProtoReducer = slice.reducer;
export default inboxProtoReducer;

