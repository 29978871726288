import fieldManagement from "./fieldManagement";
import channel from "./channel";
import habitatImprovement from "./habitatImprovement";

const questionnaireService = [
    {
        matcher: 'questionnaireAns/fieldManagement',
        reply: config => {
            return [200, fieldManagement];
        }
    },
    {
        matcher: 'questionnaireAns/channel',
        reply: config => {
            return [200, channel];
        }
    },
    {
        matcher: 'questionnaireAns/habitatImprovement',
        reply: config => {
            return [200, habitatImprovement];
        }
    }
]

export default questionnaireService;