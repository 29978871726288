import {KeyboardDatePicker} from "@material-ui/pickers";
import React from "react";
import UserEvent from "../../core/UserEvent";
import lang from "../../core/lang";
import PropTypes from 'prop-types';
import Icon from "../Icon";
import time from "../../core/time";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {useTheme} from "@material-ui/core";
import i18n from "../../core/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        '& input': {
            color: props => props.color
        },
        '& button': {
            pointerEvents: props => props.readOnly ? 'none' : ''
        }
    }
}));

function DateField(props) {
    const {className, value, initialFocusedDate, label, helperText, readOnly, disabled, required, variant, fullWidth, size} = props;
    const style = {...props.style};
    const {format, view, maxDate, minDate} = props;
    const theme = useTheme();

    const handleChange = (event) => {
        const date = event?.toDate();
        let value;
        if(lang.isNotNullOrUndefined(date)) {
            value = time.of(date.getFullYear(), date.getMonth(), date.getDate());
        } else {
            value = null;
        }
        props.onChange && props.onChange(new UserEvent({value}, event));
    }

    const d = lang.isEmpty(value) ? null : value;

    const icon = readOnly ? <Icon></Icon> : <Icon>today</Icon>;

    const calcColor = (theme, color) => {
        const tokens = color.split('.');
        let themeColor = theme.palette[tokens[0]];
        for(let i = 1; i<tokens.length; i++) {
            if(themeColor[tokens[i]]) {
                themeColor = themeColor[tokens[i]];
            } else {
                break;
            }
        }
        return themeColor || color;
    };
    const defaultColor = props.readOnly ? theme.palette.text.primary : 'reset';
    const color = lang.isFunction(props.color) ? props.color(value) : props.color;
    const effectiveColor = color ? calcColor(theme, color) : defaultColor;

    const classes = useStyles({color: effectiveColor, readOnly});

    if(readOnly) {
        style.pointerEvents = '';
    }

    const views = (view => {
        if(view === 'year') {
            return ['year'];
        } else if(view == 'month') {
            return ['year', 'month'];
        } else {
            return undefined;
        }
        return views;
    })(view);

    let ariaLabel;
    if(props["aria-label"]) {
        ariaLabel = i18n.translate(props["aria-label"]);
    } else {
        ariaLabel = i18n.translate(lang.isString(props.label) ? props.label : '');
    }

    return (
        <KeyboardDatePicker classes={classes} className={clsx('appfuse-DateField', className)} style={style}
            value={d}
            autoOk
            variant="inline"
            size={size}
            inputVariant={variant}
            label={label}
            format={format}
            views={views}
            InputAdornmentProps={{ position: "end"}}
            KeyboardButtonProps={{"aria-label": ariaLabel}}
            fullWidth={fullWidth}
            disabled={disabled}
            required={required}
            onChange={handleChange}
            maxDate={maxDate}
            minDate={minDate}
            keyboardIcon={icon}
            helperText={helperText}
            initialFocusedDate={initialFocusedDate}
            inputProps={{readOnly, "aria-label": ariaLabel}}
            aria-label={ariaLabel}
        />
    )
}

DateField.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ]),
    onChange: PropTypes.func,
    name: PropTypes.string,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    fullWidth: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    format: PropTypes.string,
    view: PropTypes.oneOf(["year", "month", "date"]),
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    helperText: PropTypes.string,
    initialFocusedDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ]),
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    "aria-label": PropTypes.string,
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ])
}

DateField.defaultProps = {
    format: "YYYY-MM-DD"
}

export default DateField;