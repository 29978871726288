const approvedProcessingDrafts = [
    {
        'id': '1',
        "farmerName":"王小農",
        "applyCategory":"approvedProcessing",
        "appType":"initial",
        "createdOn":"2023-03-02T08:20:10.000Z",
        "name": "王小農",
        "idNo": "A123456789",
        "birthday": "1999-05-02",
        "taxIdNo": "123456789",
        "companyRegistrationDate": "2012-08-05",
        "permanentAddress": {
            "county": "D",
            "town": "D23",
            "street": "將軍路5號",
            "zip": "725",
        },
        "factoryInfos": [
            {
                "applicationCategory": "self-processing-foodProcessing",
                "name": "土城廠",
                "contactPerson": "陳育豪",
                "products": [
                    {
                        "productName": "產品一"
                    },
                    {
                        "productName": "產品二"
                    }
                ]
            },
            {
                "applicationCategory": "commissionedProcessing-foodProcessing",
                "name": "板橋廠",
                "contactPerson": "陳茜茜",
                "products": [
                    {
                        "productName": "產品一"
                    }
                ]
            }
        ],
        "products": [
            {
                "name": "產品一",
                "factories": [
                    "土城廠",
                    "板橋廠"
                ]
            },
            {
                "name": "產品二",
                "factories": [
                    "土城廠"
                ]
            }
        ]
    }
];

export default approvedProcessingDrafts;