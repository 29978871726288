const inboxes = [
    {
        "id": "1",
        "createdOn": "2019-09-15T08:20:10.000Z",
        "appNo": "GA00150-C-0-00-001",
        "farmerName": "王森林",
        "category": "crop",
        "type": "initial",
        "phase": "documentReview",
        "activity": "documentReview_S40",
        "cropApplication": {
            "id":"1",
            "applicant":"王森林",
            "idNo": "A123456789",
            "farmName": "興興",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "mobilePhone": "0912345678",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "species2",
            "species": "青蛙",
            "category":"crop",
            "type":"initial",
            "createdOn":"2021-05-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },

        "examination": "supplement",
        "missingRequiredFiles": {
            "required1": true,
            "required2": true,
            "required3": true,
            "required4": true,
            "required1FieldNos": ["F03", "F04"],
            "required2FieldNos": ["F01", "F02", "F04"],
        },
        "missingConditionalFiles": ['conditional4', 'conditional5'],
    },
    {
        "id": "2",
        "createdOn": "2019-09-15T08:20:10.000Z",
        "appNo": "GA00150-C-0-00-001",
        "farmerName": "王森林",
        "category": "crop",
        "type": "initial",
        "phase": "documentReview",
        "activity": "documentReview_S50",
        "cropApplication": {
            "id":"2",
            "applicant":"王森林",
            "idNo": "A123456789",
            "farmName": "興興",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "mobilePhone": "0912345678",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "species2",
            "species": "青蛙",
            "category":"crop",
            "type":"initial",
            "createdOn":"2021-05-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },

        "examination": "reCoaching",
    },
    {
        "id": "3",
        "createdOn": "2019-09-15T08:20:10.000Z",
        "appNo": "GA00150-C-0-00-001",
        "farmerName": "王森林",
        "category": "crop",
        "type": "initial",
        "phase": "inspectionReview",
        "activity": "inspectionReview_S50",
        "cropApplication": {
            "id":"3",
            "applicant":"王森林",
            "idNo": "A123456789",
            "farmName": "興興",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "mobilePhone": "0912345678",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "species2",
            "species": "青蛙",
            "category":"crop",
            "type":"initial",
            "createdOn":"2021-05-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },

        "examination": "supplement",
    },
    {
        "id": "4",
        "createdOn": "2019-09-15T08:20:10.000Z",
        "appNo": "GA00150-C-0-00-001",
        "farmerName": "王森林",
        "category": "crop",
        "type": "initial",
        "phase": "recheckReview",
        "activity": "recheckReview_S10",
        "cropApplication": {
            "id":"4",
            "applicant":"王森林",
            "idNo": "A123456789",
            "farmName": "興興",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "mobilePhone": "0912345678",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "species2",
            "species": "青蛙",
            "category":"crop",
            "type":"initial",
            "createdOn":"2021-05-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },

        "drugTestResult": "pass",
        "drugTestReport": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A05藥檢報告.pdf",
            "lastModified": 0
        },
    },
    {
        "id":"5",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00090-C-0-00-001",
        "category": "mark",
        "activity":"S30",
        "farmerName":"王森林",
        "markApplication": {
            "farmer": "王森林",
            "applyDate": "2021-03-05T08:20:10.000Z",
            "contactPerson": "吳小花",
            "contactPersonPhone": "0919234098",
            "receiveType": "byPost",
            "mailingAddress": {
                "city":"63",
                "district":"中正區",
                "street":"忠孝東路",
                "zipCode":"100"
            },
            "inventory": 450,
            "applicationItem": ["蘿蔔", "地瓜"],
            "style": "overprint",
            "applicationItemDetail": [
                {
                    "crop": "蘿蔔",
                    "content": [
                        {
                            "spec": "蘿蔔規格"
                        }
                    ]
                },
                {
                    "crop": "地瓜",
                    "content": [
                        {
                            "spec": "地瓜規格1",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "地瓜包裝1.png",
                                    "lastModified": 0
                                }
                            ]
                        },
                        {
                            "spec": "地瓜規格2",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "地瓜包裝2.png",
                                    "lastModified": 0
                                }
                            ]
                        },
                        {
                            "spec": "地瓜規格3",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "地瓜包裝3.png",
                                    "lastModified": 0
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        "review": "reject",
        "supplementDescription": "申請項目包裝樣式未齊全",
    },
]

export default inboxes;