import {createSlice} from "@reduxjs/toolkit";
import {json} from "../../lib/appfuse-react/core/json";

const name = 'util';

const initialState = {
    county: []
};

const slice = createSlice({
    name,
    initialState,
    reducers:{
        setCounty(state, action) {
            state.county = json.toJSON(action.payload);
        }
    }
})

export const { setCounty } = slice.actions;

export const selectCounty = state => json.fromJSON(state[name].county);

const utilReducer = slice.reducer;
export default utilReducer;