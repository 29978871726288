import {Avatar, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Tooltip, Typography} from "@material-ui/core";
import UserEvent from "../../core/UserEvent";
import Badge from "@material-ui/core/Badge";
import lang from "../../core/lang";
import {makeStyles} from "@material-ui/core/styles";
import i18n from "../../core/i18n";
import React from 'react';
import Icon from "../Icon";
import useRouter from "../../hook/useRouter";

const useStyles = makeStyles(theme => ({
    root: {
        cursor: props => props.cursor
    },
    avatar: {
        width: '2.4rem',
        height: '2.4rem',
    },
    listItemImage: {
        minWidth: "unset",
        marginRight: '0.5rem'
    }
}));

function DockItem(props) {
    const { value, text, icon, locked, disabled, badge, description } = props;
    const cursor = (locked || disabled) ? 'not-allowed' : 'pointer';
    const classes = useStyles({cursor});
    const router = useRouter();
    const handleClick = event => {
        if(locked || disabled) return;
        props.onClick && props.onClick(new UserEvent({value}, event));
    }

    const title = (
        <React.Fragment>
            <Typography variant="h6">{i18n.translate(text)}</Typography>
            <Typography >{i18n.translate(description)}</Typography>
        </React.Fragment>
    )
    const isImage = lang.isString(icon) && /.*\.(svg|png)$/.test(icon);
    let image;
    if(isImage) {
        image = <Avatar className={classes.avatar} src={router.resolve(icon)} variant="rounded" />;
    } else {
        image = <Icon size="large" color="primary">{icon}</Icon>;
    }
    lang.isNotNullOrUndefined(badge) && (image = <Badge badgeContent={badge} color="error">{image}</Badge>);
    if(isImage) {
        image = <ListItemAvatar>{image}</ListItemAvatar>;
    } else {
        image = <ListItemIcon className={classes.listItemImage}>{image}</ListItemIcon>;
    }
    return (
        <Tooltip arrow title={title} placement="right">
            <ListItem className={classes.root} onClick={handleClick}>
                {image}
                <ListItemText disableTypography>
                    <Typography noWrap>{i18n.translate(text)}</Typography>
                </ListItemText>
            </ListItem>
        </Tooltip>
    )
}

export default DockItem;