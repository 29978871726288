import {createSlice} from "@reduxjs/toolkit";
import {json} from "../../lib/appfuse-react/core/json";

const name = 'cropDraft';

const initialState = {
    selection: [],
    pageNumber: 0,
    pageSize: 10,
    draft: {},
    activeStep: 0,
};

const slice = createSlice({
    name,
    initialState,
    reducers:{
        setSelection(state, action) {
            state.selection = action.payload;
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setDraft(state, action) {
            state.draft = json.toJSON(action.payload);
        },
        setActiveStep(state, action) {
            state.activeStep = action.payload;
        },
    }
})

export const {setSelection, setPageNumber, setPageSize, setSort, setDraft, setActiveStep} = slice.actions;

export const selectSelection = state => state[name].selection;
export const selectPageNumber = state => state[name].pageNumber;
export const selectPageSize = state => state[name].pageSize;
export const selectDraft = state => json.fromJSON(state[name].draft);
export const selectActiveStep = state => state[name].activeStep;

const cropDraftReducer = slice.reducer;
export default cropDraftReducer;