import { createSlice } from '@reduxjs/toolkit';
import logger from "../../lib/appfuse-react/core/logger";
import cookie from "../../lib/appfuse-react/core/cookie";
import lang from "../../lib/appfuse-react/core/lang";
import json from "../../lib/appfuse-react/core/json";

const LOGGER = logger.getLogger('authReducer');
const SLICE_NAME = 'auth';

const EXPIREDDAYS = 90;
const PERSISTENTSESSION_KEY = 'persistentSession';
const AUTHORIZATION_KEY = 'authorization';

const initialState = {
  token: cookie.get(AUTHORIZATION_KEY),
  persistentSession: cookie.getAsBoolean(PERSISTENTSESSION_KEY, 'true'),
  authenticated: false,
  permissions: [],
  userInfo: {},
  applets: [],
  inboxCounts: {}
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setPersistentSession(state, action) {
      state.persistentSession = action.payload;
    },
    setAuthenticated(state, action) {
      state.authenticated = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = json.toJSON(action.payload);
    },
    setApplets(state, action) {
      state.applets = action.payload;
    },
    setInboxCounts(state, action) {
      state.inboxCounts = json.toJSON(action.payload);
    }
  }
});

export const { reducer } = slice;

export const {setAuthenticated, setApplets, setUserInfo, setInboxCounts} = slice.actions;

export const setToken = token => dispatch => {
  // 必須先處理 cookie 的內容，避免 dispatch 因為 page reload 後 redux store 不存在發生錯誤。
  const persistentSession = cookie.getAsBoolean(PERSISTENTSESSION_KEY, 'true');
  if(persistentSession) {
    if(lang.isNullOrUndefined(token)) {
      cookie.delete(AUTHORIZATION_KEY);
    } else {
      cookie.set(AUTHORIZATION_KEY, token, EXPIREDDAYS);
    }
  }
  dispatch(slice.actions.setToken(token));
}

export const setPersistentSession = persistentSession => dispatch => {
  // 必須先處理 cookie 的內容，避免 dispatch 因為 page reload 後 redux store 不存在發生錯誤。
  cookie.set(PERSISTENTSESSION_KEY, persistentSession, EXPIREDDAYS);
  if(lang.isFalse(persistentSession)) {
    cookie.delete(AUTHORIZATION_KEY);
  }
  dispatch(slice.actions.setPersistentSession(persistentSession));
}

export const selectToken = state => state[SLICE_NAME].token;
export const selectPersistentSession = state => state[SLICE_NAME].persistentSession;
export const selectUserInfo = state => json.fromJSON(state[SLICE_NAME].userInfo);
export const selectApplets = state => state[SLICE_NAME].applets;
export const selectInboxCounts = state => json.fromJSON(state[SLICE_NAME].inboxCounts);
export const isAuthenticated = state => state[SLICE_NAME].authenticated;

const authReducer = reducer;

export default authReducer;
