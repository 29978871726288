const habitatImprovement = {
    "sections":[
        {
            "subject":"species",
            "questions":[
                {
                    "description":"目前田區有哪些野生動物",
                    "type":"oeq"
                },
                {
                    "description":"保育對象的特性簡述",
                    "type":"oeq",
                    "helperText":"可至網站或書籍查詢相關資料後填寫，包含食性、棲息環境、出沒時間"
                },
                {
                    "description":"保育對象在田區現況描述",
                    "type":"oeq",
                    "helperText":"例如：族群數量、平常出沒地點、時間或季節性及其他動物說明"
                },
                {
                    "description":"田間操作模式與動物保育關聯性",
                    "type":"oeq",
                    "helperText":"請說明：作物種類、輪作體系、保育動物與各種作物關係或其他文化性及特殊性"
                },
                {
                    "description":"棲地改善措施",
                    "type":"oeq"
                }
            ]
        },
        {
            "subject":"habitat",
            "questions":[
                {
                    "description":"營造工法",
                    "type":"oeq",
                    "helperText":"請說明所採行的措施，例如：以生態工法營造生態池，營造措施包括砌石、土堤、水深大小、水生植物種類、底質級配等等說明。"
                },
                {
                    "description":"營造位置",
                    "type":"oeq"
                },
                {
                    "description":"營造植物種類",
                    "type":"oeq"
                },
                {
                    "description":"其他說明",
                    "type":"oeq"
                },
                {
                    "description":"野生動物利用所營造棲地現況描述",
                    "type":"oeq",
                    "helperText":"請說明：有那些物種、如何利用、時間或季節性、族群數量等等說明"
                },
                {
                    "description":"田間操作模式與棲地維護關聯性",
                    "type":"oeq",
                    "helperText":"請說明：作物種類、輪作體系、操作情形等等與棲地維護之關係"
                }
            ]
        }
    ]
}

export default habitatImprovement;