import approvedProcessingDrafts from "./approvedProcessingDrafts";

const approvedProcessingDraftService = [
    {
        matcher: 'approvedProcessingDrafts',
        reply: config => {
            return [200, approvedProcessingDrafts];
        }
    },
    {
        matcher: /approvedProcessingDrafts\/(.*)/,
        reply: config => {
            const id = /approvedProcessingDrafts\/(.*)/.exec(config.url)[1];
            const data = approvedProcessingDrafts.find(item => item.id === id);
            return [200, data];
        }
    },
]

export default approvedProcessingDraftService;