import ajax from "../../lib/appfuse-react/core/ajax";

const registrationService = {
    validate: async (data) => {
        await ajax.post('registrations/validate', data);
    },
    requestToken: async (phone, email) => {
        const response = await ajax.get(`registrations/sendVerificationCode/${phone}`, {email});
        return response.ok;
    },
    verifyEmail: async (phone, token) => {
        const response = await ajax.get(`registrations/confirmVerificationCode/${phone}/${token}`);
        return response.ok;
    },
    registration: async (data) => {
        const response = await ajax.post('registrations', data);

        return response.data;
    }
}

export default registrationService;