import cropDrafts from "./cropDrafts";

const cropDraftService = [
    {
        matcher: 'cropDrafts',
        reply: config => {
            return [200, cropDrafts];
        }
    },
    {
        matcher: /cropDrafts\/(.*)/,
        reply: config => {
            const id = /cropDrafts\/(.*)/.exec(config.url)[1];
            const draft = cropDrafts.find(draft => draft.id === id);
            return [200, draft];
        }
    }
]

export default cropDraftService;