const channel = {
    "sections":[
        {
            "questions":[
                {
                    "description":"請問您是否願意曝光部分資訊，以利消費者聯絡呢？",
                    "type":"scq",
                    "options":["是","否"],
                    "questions":[
                        {
                            "condition": "是",
                            "description":"連絡電話",
                            "type":"oeq"
                        },
                        {
                            "condition": "是",
                            "description":"傳真",
                            "type":"oeq"
                        },
                        {
                            "condition": "是",
                            "description":"電子信箱",
                            "type":"oeq"
                        },
                        {
                            "condition": "是",
                            "description":"自有網站/FB",
                            "type":"oeq"
                        },
                        {
                            "condition": "是",
                            "description":"其他販售管道",
                            "type":"oeq"
                        },
                        {
                            "condition": "是",
                            "description":"作物販售-作物名稱",
                            "type":"oeq"
                        },
                        {
                            "condition": "是",
                            "description":"作物販售-販售期間",
                            "type":"oeq"
                        },
                        {
                            "condition": "是",
                            "description":"作物販售-年產量",
                            "type":"oeq"
                        },
                        {
                            "condition": "是",
                            "description":"是否能接受消費者訂購且獨自包裝出貨",
                            "type":"scq",
                            "options":["是","否"]
                        },
                        {
                            "condition": "是",
                            "description":"其他想公開訊息或注意事項",
                            "type":"oeq",
                            "required":false
                        },
                    ]
                }
            ]
        }
    ]
}

export default channel;