import React, { useEffect } from "react";
import { withExplorer } from "../../layout/Explorer";
import { useSelector } from "../../store";
import { isAuthenticated } from "../../reducer/iam/authReducer";
import Box from "../../lib/appfuse-react/component/Box";
import useRouter from "../../lib/appfuse-react/hook/useRouter";
import { Typography, useTheme } from "@material-ui/core";
import Paper from "../../lib/appfuse-react/component/Paper";
import i18n from "../../lib/appfuse-react/core/i18n";
import { Redirect } from "react-router-dom";


function Home() {
    const theme = useTheme();
    const router = useRouter();
    const backgroundImage = router.resolve(theme.brand.banner);
    const authenticated = useSelector(isAuthenticated);

    useEffect(() => {
    }, []);

    const route = authenticated ? <Redirect to="/main" /> : (
        <Paper className="app-home" fullWidth fullHeight backgroundImage={backgroundImage}
            display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={5}>
            <Paper width="80%" minHeight="20%" backgroundOpacity={0.9} padding={2}>
                <Typography variant="h1" align="center">Banner</Typography>
            </Paper>
            <Paper width="80%" minHeight="40%" backgroundOpacity={0.9} padding={2}>
                <Typography variant="h1" align="center">公告</Typography>
            </Paper>
            <Box display="flex" width="80%" justifyContent="space-around" >
                {/*<Paper elevation={3} backgroundColor="secondary" padding={3} backgroundOpacity={0.9} color={theme.palette.primary.contrastText}*/}
                {/*    onClick={() => router.register()} whiteSpace="pre" borderRadius={3}>*/}
                {/*    <Typography color="inherit" variant="h3" align="center">{i18n.translate("Apply\nGreen Conservation")}</Typography>*/}
                {/*</Paper>*/}
                <Paper elevation={3} backgroundColor="primary" padding={3} backgroundOpacity={0.9} color={theme.palette.primary.contrastText}
                    onClick={() => router.login()} whiteSpace="pre" borderRadius={3} >
                    <Typography color="inherit" variant="h3" align="center">{i18n.translate("Sign in as Farmer")}</Typography>
                </Paper>
            </Box>
        </Paper>
    );

    return route;
}

export default withExplorer(Home);