import {Box} from "@material-ui/core";
import PropTypes from 'prop-types';

function Space(props) {
    const {display, vertical, spacing} = props;

    const marginX = vertical ? undefined : spacing / 2;
    const marginY = vertical ? spacing / 2 : undefined;

    return <Box display={display} marginX={marginX} marginY={marginY} />
}

Space.propTypes = {
    display: PropTypes.oneOf(['inline', 'block']),
    spacing: PropTypes.number,
    vertical: PropTypes.bool
}

Space.defaultProps = {
    display: 'inline',
    spacing: 1,
    vertical: false
}

export default Space;