import {createSlice} from "@reduxjs/toolkit";
import {json} from "../../lib/appfuse-react/core/json";

const name = 'disqualificationApplication';

const initialState = {
    draft: {},
};

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setDraft(state, action) {
            state.draft = json.toJSON(action.payload);
        }
    }
});

export const { setDraft } = slice.actions;

export const selectDraft = state => json.fromJSON(state[name].draft);

const disqualificationApplicationReducer = slice.reducer;
export default disqualificationApplicationReducer;