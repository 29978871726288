import {mock} from "../../conf/context";

const productService = {
    find: async (options = {}) => {
        const { predicate = '', sort = '', page = 0, size = 10 } = options;

        const response = await mock.get("products", { predicate, sort, page, size });
        return response.data;
    },
    get: async (id) => {
        const response = await mock.get(`products/${id}`);
        return response.data;
    },
    delete: async (id) => {
        await mock.delete(`products/${id}`);
    }
}

export default productService;