import { createSlice } from '@reduxjs/toolkit';
import productService from "../../service/sales/productService";
import Predicate from "../../lib/appfuse-react/core/Predicate";
import logger from "../../lib/appfuse-react/core/logger";
import { postError } from "../app/appReducer";
import {json} from "../../lib/appfuse-react/core/json";
import lang from "../../lib/appfuse-react/core/lang";
import Sort from "../../lib/appfuse-react/core/Sort";

const LOGGER = logger.getLogger('productReducer');

const name = 'product';

const initialState = {
    data: [],
    selection: null,
    pageNumber: 0,
    pageSize: 15,
    criteria: {},
    sort: null,
    drafts: []
};

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setData(state, action) {
            state.data = json.toJSON(action.payload);
        },
        setSelection(state, action) {
            state.selection = action.payload;
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setCriteria(state, action) {
            state.criteria = action.payload;
        },
        setSort(state, action) {
            state.sort = action.payload;
        },
        addDraft(state, action) {
            const draft = json.toJSON(action.payload);
            const {id} = draft;
            const index = state.drafts.findIndex(draft => draft.id===id);
            if (index === -1) {
                state.drafts.push(draft);
            } else {
                state.drafts[index] = draft;
            }
        },
        removeDraft(state, action) {
            const id = action.payload;
            const drafts = state.drafts;
            const index = drafts.findIndex(draft => (draft.id = id));
            if (index !== -1) {
                state.drafts = drafts.slice(0, index).concat(drafts.slice(index + 1));
            }
        }
    }
});

export const { setSelection, setPageNumber, setPageSize, addDraft, removeDraft } = slice.actions;
export const { reducer } = slice;


export const setCriteria = (criteria) => dispatch => {
    dispatch(slice.actions.setCriteria(criteria));
    dispatch(debounceFetchData);
}

/**
 * createSlice 產生的 action creator 所接收的 action.payload 只能是下列 serializable 資料型別：
 * - string
 * - number
 * - boolean
 * - null or undefined
 * - array
 * - plain object
 * 但是 selection 的資料型別是 TableSelection，所以我們需要建立一個新的 action creator 來取代原有的．
 */
export const setSort = (sort) => dispatch => {
    dispatch(slice.actions.setSort(json.toJSON(sort)));
    dispatch(debounceSortData);
}

export const selectData = state => json.fromJSON(state[name].data);
export const selectSelection = state => state[name].selection;
export const selectSort = state => {
    const jsonObject = state[name].sort;
    if(lang.isNullOrUndefined(jsonObject)) return new Sort();

    const sort = json.fromJSON(jsonObject, Sort);
    return sort;
}
export const selectPageNumber = state => state[name].pageNumber;
export const selectPageSize = state => state[name].pageSize;
export const selectCriteria = state => state[name].criteria;
export const selectDrafts = state => json.fromJSON(state[name].drafts);
export const selectDraft = (id) => (state) => selectDrafts(state).find(draft => draft.id===id);

const debounceFetchData = lang.debounce(dispatch => dispatch(fetchData()), 1000, true);
export const fetchData = () => async (dispatch, getState) => {
    const criteria = selectCriteria(getState());
    let predicate;
    if(lang.isString(criteria)) {
        predicate = Predicate.like('name', criteria.name)
            .and(Predicate.like('description', criteria.description));
    } else {
        predicate = Predicate.like('name', criteria.name)
            .and(Predicate.like('description', criteria.description))
            .and(Predicate.in('category', criteria.category))
            .and(Predicate.has('keywords', criteria.keywords))
            .and(Predicate.ge('price', criteria.price))
            .and(Predicate.ge('createdOn',criteria.createdOn));
    }
    try {
        const products = await productService.find({predicate});
        dispatch(slice.actions.setData(products));
        dispatch(sortData());
    } catch(e) {
        dispatch(postError(e));
    }
};

const debounceSortData = lang.debounce(dispatch => dispatch(sortData()), 1000, true);
export const sortData = () => (dispatch, getState) => {
    const sort = selectSort(getState());
    const data = selectData(getState());
    const sortedData = lang.sort(data, sort);
    dispatch(slice.actions.setData(sortedData));
}

export const fetchDraft = (id) => async (dispatch) => {
    try {
        const product = await productService.get(id);
        dispatch(slice.actions.addDraft(product));
    } catch (e) {
        dispatch(postError(e));
    }
};

export const deleteData = (list) => async (dispatch, getState) => {
    await productService.delete(list);
    dispatch(fetchData());
}

const productReducer = reducer;

export default productReducer;