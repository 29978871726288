import { mock } from "../../conf/context";
import ajax from "../../lib/appfuse-react/core/ajax";
import {setAuthenticated, setToken} from "../../reducer/iam/authReducer";
import store from "../../store";

const authService = {
    login: async (authorization, param) => {
        let response;
        try {
            response = await ajax.get('login', param, {
                authorization: authorization
            });
            if(response.authorization) {
                store.dispatch(setToken(response.authorization));
                store.dispatch(setAuthenticated(true));
            }
        } catch(e) {
            store.dispatch(setToken(null));
            store.dispatch(setAuthenticated(false));
            throw e;
        }

        return response.data;
    },
    logout: () => {
        store.dispatch(setToken(null));
        store.dispatch(setAuthenticated(false));
    },
    fetchApplets: async () => {
        const response = await mock.get('applets');
        return response.data;
    },
    verify: async (token, pin) => {
        const authorization = `Bearer ${token}`;
        const response = await ajax.get('login', {}, {
            authorization: authorization,
            headers: {pin}
        });
        if(response.authorization) {
            store.dispatch(setToken(response.authorization));
            store.dispatch(setAuthenticated(true));
        }
    },
    fetchUserInfo: async () => {
        const response = await ajax.get('accounts/userInfo');
        return response.data;
    },
    fetchInboxCounts: async () => {
        const response = await ajax.get("home/messenger/web");
        return response.data;
    },
    forgotPassword: async (username) => {
        await ajax.get(`accounts/forgetPassword/${username}`);
    },
    changePassword: async (data) => {
        await ajax.post('accounts/changePassword', data);
    },
}

export default authService;