const applets = [
    {
        "text": "Crop Application",
        "icon": "/image/application/wheat.svg",
        "path": "CropDraft",
        "id": "CropDraftApplet"
    },
    {
        "text": "Approved Application",
        "icon": "/image/application/gears.svg",
        "path": "approvedProcessingDraft",
        "id": "ApprovedProcessingDraftApplet"
    },
    {
        "text": "Inbox",
        "icon": "/image/application/inbox(2).svg",
        "path": "Inbox",
        "id": "Inbox"
    },
    {
        "text": "Inbox Proto",
        "icon": "/image/application/inbox(2).svg",
        "path": "InboxProto",
        "id": "InboxProto"
    },
    {
        "text": "Mark Application",
        "icon": "/image/application/mark-application.svg",
        "path": "Mark",
        "id": "MarkApplet"
    },
    {
        "text": "Disqualification",
        "icon": "/image/application/disqualification.svg",
        "path": "Disqualification",
        "id": "DisqualificationApplicationApplet"
    },
    {
        "text": "Crop Application History",
        "icon": "/image/application/clock.svg",
        "path": "CropHistory",
        "id": "CropHistoryApplet"
    },
    {
        "text": "Approved Application History",
        "icon": "/image/application/clock.svg",
        "path": "approvedProcessingHistory",
        "id": "ApprovedProcessingHistoryApplet"
    }
]


export default applets;