import { Mock } from "../lib/appfuse-react/core/ajax";
import salesService from "../mock/sales";
import iamService from "../mock/iam";
import contentService from "../mock/content";
import applicationDraftService from "../mock/applicationDraft";
import questionnaireServices from "../mock/questionnaire";
import inboxesService from "../mock/inbox";
import questionnaireAnsServices from "../mock/questionnaireAns";
import markApplicationService from "../mock/markApplication";
import approvedProcessingDraftService from "../mock/approvedProcessingDraft";


const mock = (function() {
    const mock = new Mock();
    mock.use(iamService);
    mock.use(contentService);
    mock.use(salesService);
    mock.use(applicationDraftService);
    mock.use(questionnaireServices);
    mock.use(inboxesService);
    mock.use(questionnaireAnsServices);
    mock.use(markApplicationService);
    mock.use(approvedProcessingDraftService)
    return mock;
}());

const context = {mock};

export default context;

export {mock};