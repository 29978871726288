import appReducers from '../reducer/app';
import salesReducers from "../reducer/sales";
import iamReducers from "../reducer/iam";
import contentReducers from "../reducer/content";
import applicationDraftReducers from "../reducer/applicationDraft";
import inboxReducers from "../reducer/inbox";
import markApplicationReducers from "../reducer/markApplication";
import disqualificationApplicationReducers from "../reducer/disqualificationApplication";
import utilReducers from "../reducer/util";
import approvedProcessingDraftReducers from "../reducer/approvedProcessingDraft";
import inboxProtoReducers from "../reducer/inboxProto";
import cropHistoryReducers from "../reducer/cropHistory";
import approvedHistoryReducers from "../reducer/approvedHistory";

const rootReducer = {
    ...appReducers,
    ...iamReducers,
    ...salesReducers,
    ...contentReducers,
    ...applicationDraftReducers,
    ...inboxReducers,
    ...markApplicationReducers,
    ...disqualificationApplicationReducers,
    ...utilReducers,
    ...approvedProcessingDraftReducers,
    ...inboxProtoReducers,
    ...cropHistoryReducers,
    ...approvedHistoryReducers
};

export default rootReducer;
