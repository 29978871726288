import lang from "../../lib/appfuse-react/core/lang";
import markApplication from "./markApplication";

const markApplicationService = [{
    matcher: 'markApplication',
    reply: config => {
        if(config.method==='post') {
            return [200, markApplication[0]];
        } else {
            const {predicate} = config.params;
            let data;
            const selector = predicate?.node()?.selector?.();
            const children = predicate?.node()?.children?.();
            if(lang.isNotEmpty(selector)) {
                const value = predicate.node().expectation();
                if(lang.isEmpty(value)) {
                    return [200, markApplication];
                }
                if(selector==='applyDateStart') {
                    data = markApplication.filter(mark => mark.applyDate >= value);
                } else if(selector==='applyDateEnd') {
                    data = markApplication.filter(mark => mark.applyDate <= value);
                }
            } else if(lang.isNotEmpty(children)) {
                let tmp = markApplication;
                for(let child of children) {
                    const value = child.expectation();
                    if(lang.isEmpty(value)) {
                        continue;
                    }
                    const selector = child.selector();
                    if(selector==='applyDateStart') {
                        tmp = tmp.filter(mark => mark.applyDate >= value);
                    } else if(selector==='applyDateEnd') {
                        tmp = tmp.filter(mark => mark.applyDate <= value);
                    }
                }
                data = tmp;
            } else {
                data = markApplication;
            }
            return [200, data];
        }
    }
}, {
    matcher: /markApplication\/(.*)/,
    reply: config => {
        const id = /markApplication\/(.*)/.exec(config.url)[1];
        const data = markApplication.find(mark => mark.id===id);
        return [200, data];
    }
}];

export default markApplicationService;