import {Chip, TextField} from "@material-ui/core";
import React, {useEffect, useReducer, useState} from "react";
import i18n from "../../core/i18n";
import UserEvent from "../../core/UserEvent";
import lang from "../../core/lang";
import PropTypes from "prop-types";
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import Box from "../Box";
import clsx from "clsx";
import numeral from "../../core/numeral";
import time from "../../core/time";
import code from "../../core/code";
import Typography from "@material-ui/core/Typography";
import Icon from "../Icon";
import reactjs from "../../core/reactjs";
import Sort from "../../core/Sort";

const useStyles = makeStyles(theme => ({
    endAdornment: {
        display: props => props.readOnly ? 'none' : ''
    },
    inputRoot: {
        paddingRight: props => props.readOnly ? "0.5rem!important" : "6rem",
        fontFamily: props => props.fontFamily,
        color: props => props.readOnly ? theme.palette.text.primary : 'reset',
    },
    option: {
        pointer: 'default',
        '& .disabled': {
            color: theme.palette.text.disabled
        },
        '& .locked': {
            color: theme.palette.text.disabled,
        },
        // 僅有部分瀏覽器支援
        '&:has(.disabled)': {
            // pointerEvents: 'none'
        },
        // 僅有部分瀏覽器支援
        '&:has(.locked)': {
            // pointerEvents: 'none'
        }
    }
}));

const useChipStyle = makeStyles(theme => ({
    root: {
        color: 'inherit',
        borderColor: 'inherit',
        fontFamily: props => props.fontFamily
    },
    deleteIcon: {
        color: 'inherit',
        display: props => props.readOnly ? 'none' : ''
    }
}));

function validate(props) {
    if(lang.isNotNullOrUndefined(props.value)) {
        if(props.multiple) {
            if(!lang.isArray(props.value))
                throw new TypeError('multiple must be false when value is not an array.');
        } else {
            if(lang.isArray(props.value))
                throw new TypeError('multiple must be true when value is an array.');
        }
    }
}

function resolveValue(option, accessor) {
    let value;
    if (lang.isFunction(accessor)) {
        value = accessor(option);
    } else if (lang.isNullOrUndefined(option) || lang.isPrimitive(option)) {
        value = option;
    } else {
        value = lang.isObject(option) ? option[accessor] : option;
    }
    return value;
}

/**
 * 渲染文字
 * 產生的文字內容是適合顯示的格式，格式化過，並且可以是 React Element。
 * @param option
 * @param textAccessor
 * @param valueAccessor
 * @param formatter
 * @param pattern
 * @returns {*}
 */
function renderText(option, textAccessor, valueAccessor, {formatter, pattern}) {
    let text;
    if(lang.isFunction(textAccessor)) {
        text = textAccessor(option);
    } else if(lang.isPrimitive(option)) {
        text = option;
    } else {
        text = lang.has(option, textAccessor) ? option[textAccessor] : resolveValue(option, valueAccessor);
    }
    return reactjs.isElement(text) ? text : format(text, formatter, pattern);
}

/**
 * 解析文字
 * 產生的文字是適合與輸入內容對照的文字，沒有格式化過，是純文字內容。
 * @param option
 * @param textAccessor
 * @param valueAccessor
 * @returns string | number
 */
function resolveText(option, textAccessor, valueAccessor) {
    let text;
    if(lang.isFunction(textAccessor)) {
        const any = textAccessor(option);
        text = reactjs.isElement(any) ? parseTextElement(any).text : any;
    } else if(lang.isPrimitive(option)) {
        text = option;
    } else {
        text = lang.has(option, textAccessor) ? option[textAccessor] : resolveValue(option, valueAccessor);
    }
    return text;
}

function parseTextElement(textElement) {
    const children = React.Children.toArray(textElement.props.children);
    let text = "";
    for(const child of children) {
        if(lang.isString(child)) {
            text = text + child;
        }
    }
    const fontFamily = textElement.props.style.fontFamily;
    return {text, fontFamily};
}

function mapValueToSelection(value, options, accessor, multiple) {
    let selection;
    // Make Autocomplete in control mode to avoid the complaint when switch between controlled & uncontrolled mode.
    if(lang.isNullOrUndefined(value)) {
        selection = multiple ? [] : '';
    } else if(multiple) {
        selection = value.map(tuple => options.find(option => lang.isEqual(resolveValue(option, accessor), tuple)) || tuple);
    } else {
        selection = options.find(option => lang.isEqual(resolveValue(option, accessor), value)) || value;
    }

    return selection;
}

function resolveOptions(options, codeData, accessor, multiple) {
    let items;
    if(options) {
        items = lang.isFunction(options) ? options() : [...options];
    } else if(!lang.isEmpty(codeData)) {
        items = codeData;
    } else {
        items = [];
    }

    if(lang.isEmpty(items)) {
        return items;
    }

    const isObject = lang.isObject(items[0]);

    //@todo sort by the textAccessor
    items = isObject ? lang.sort(items, Sort.desc('priority')) : items.sort();

    if(!multiple && items.every(option => resolveValue(option, accessor)!=="")) {
        items.unshift("");
    }
    return items;
}

function isOptionDuplicated(createdOption, value, options, textAccessor, valueAccessor) {
    const values = lang.isArray(value) ? value : [value];
    const selection = mapValueToSelection(values, options, valueAccessor, true);
    const found = selection.findIndex(item => {
        const text = resolveText(item, textAccessor, valueAccessor);
        return lang.isEqual(createdOption, text);
    });
    return found !== -1;
}

function isOptionAvailable(option) {
    return !(option.disabled || option.locked || option.deprecated);
}

function findInOptions(valueOrText, options, textAccessor, valueAccessor) {
    return options.find(option => {
        const text = resolveText(option, textAccessor, valueAccessor);
        if(lang.isEqual(valueOrText, text)) {
            return true;
        }
        const value = resolveValue(option, valueAccessor);
        return !!lang.isEqual(valueOrText, value);
    });
}

const DEFAULT_VALUE_ACCESSOR = 'value';
const DEFAULT_TEXT_ACCESSOR = 'text';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SelectField(props) {
    validate(props);

    const {name, multiple, textAccessor, valueAccessor, optionRenderer,
        variant, size, fullWidth, margin, color, readOnly, disabled, required, error, focused, freeSolo, forcePopupIcon, disableCloseOnSelect,
        formatter, pattern, maxTagLength} = props;
    const style = {...props.style};
    const [inputDescriptor, setInputDescriptor] = useState({text: '', fontFamily: undefined});
    const label = lang.isString(props.label) ? i18n.translate(props.label) : props.label;
    const helperText = i18n.translate(props.helperText) || undefined;
    const [codeData, setCodeData] = useState([]);
    const [open, setOpen] = useState(false);
    const options = resolveOptions(props.options, codeData, props.valueAccessor, multiple);
    let fontFamily = lang.isFunction(props.fontFamily) ? props.fontFamily(props.value) : props.fontFamily;
    lang.isNotEmpty(inputDescriptor.fontFamily) && (fontFamily = inputDescriptor.fontFamily);

    const triggerChange = (value, event) => {
        if(multiple) {
            setInputDescriptor({text: ''});
        }
        props.onChange && props.onChange(new UserEvent({value}, event));
    }

    const handleChange = (event, selection, reason, aux) => {
        if (readOnly) return;

        if(reason==='create-option') {
            // only freeSolo can trigger change to create option.
            handleCreateOption(aux.option, event);
        } else if(reason==='select-option') {
            handleSelectOption(aux.option, event);
        } else if(reason==='remove-option') {
            if(event.key==='Enter') return;
            handleRemoveOption(aux.option, event);
        } else if(reason==='clear') {
            handleClear(event);
        }
    }

    const handleCreateOption = (createdOption, event) => {
        if(lang.isEmpty(createdOption)) return;

        if(isOptionDuplicated(createdOption, props.value, options, textAccessor, valueAccessor, {formatter, pattern})) {
            if(multiple) {
                setInputDescriptor({text: ''})
            }
            return;
        }
        const option = findInOptions(createdOption, options, textAccessor, valueAccessor, {formatter, pattern});
        let change;
        if(lang.isNullOrUndefined(option)) {
            change = createdOption;
        } else {
            if(!isOptionAvailable(option)) {
                if(multiple) {
                    setInputDescriptor({text: ''})
                }
                return;
            }
            change = resolveValue(option, valueAccessor);
        }
        const value = multiple ? [...props.value, change] : change;
        triggerChange(value, event);
    }

    const handleMatchOption = (text, event) => {
        if(lang.isEmpty(text)) return;

        if(isOptionDuplicated(text, props.value, options, textAccessor, valueAccessor)) {
            if(multiple) {
                setInputDescriptor({text: ''});
            }
        }
        const option = findInOptions(text, options, textAccessor, valueAccessor);
        if(lang.isNotNullOrUndefined(option) && isOptionAvailable(option)) {
            const change = resolveValue(option, valueAccessor);
            const value = multiple ? [...props.value, change] : change;
            triggerChange(value, event);
        } else {
            if(multiple) {
                setInputDescriptor({text: ''});
            } else {
                const selection = mapValueToSelection(props.value, options, valueAccessor, false);
                const any = renderText(selection, textAccessor, valueAccessor, { formatter, pattern });
                const inputDescriptor = reactjs.isElement(any) ? parseTextElement(any) : {text: any};
                setInputDescriptor(inputDescriptor);
            }
        }
    }

    const handleSelectOption = (option, event) => {
        if(!isOptionAvailable(option)) {
            return;
        }
        const change = resolveValue(option, valueAccessor);
        const value = multiple ? [...props.value, change] : change;

        triggerChange(value, event);
    }

    const handleRemoveOption = (option, event) => {
        const removed = resolveValue(option, valueAccessor);
        const value = props.value.filter(tuple => {
            return !lang.isEqual(tuple, removed);
        })
        triggerChange(value, event);
    }

    const handleClear = event => {
        triggerChange(multiple ? [] : null, event);
    }

    const handleFocus = event => {
        if(!multiple) {
            const selection = mapValueToSelection(props.value, options, valueAccessor, false);
            const text = resolveText(selection, textAccessor, valueAccessor);
            setInputDescriptor({text});
        }
    }
    const handleBlur = event => {
        if(readOnly) return;

        if (freeSolo) {
            handleCreateOption(inputDescriptor.text, event);
        } else {
            handleMatchOption(inputDescriptor.text, event);
        }
    }

    const handleInputChange = (event, value, reason) => {
        if(reason === 'input') {
            setInputDescriptor(prevState => ({...prevState, text: value}));
        }
    };

    const isOptionSelected = (option, value) => {
        return lang.isEqual(resolveValue(value, props.valueAccessor), resolveValue(option, props.valueAccessor));
    }

    const chipClasses = useChipStyle({readOnly, fontFamily});
    const chipColor = lang.isFunction(color) ? color() : color;
    const tagsRenderer = (value, getTagProps) => {
        return value.map((item, index) => {
            const selection = item;
            // use the item of the value when freeSolo
            let label, title;
            if(lang.isNullOrUndefined(selection)) {
                label = item;
                title = item;
            } else {
                title = renderText(selection, props.textAccessor,  props.valueAccessor, { formatter, pattern });
                label = (maxTagLength > 0) && (title.length > maxTagLength) ? `${title.substring(0, maxTagLength)}...` : title;
            }
            const key = resolveValue(item, props.valueAccessor);
            return (
                <Box color={chipColor} key={key}>
                    <Chip size={variant==='outlined' ? size : 'small'} classes={chipClasses}
                          {...getTagProps({index})}
                          variant="outlined"
                          key={key}
                          label={label} title={title}
                    />
                </Box>
            )
        });
    }

    const getOptionLabel = option => {
        return resolveText(option, textAccessor, valueAccessor);
    }

    const renderOption = (option, state) => {
        if(optionRenderer) {
            return optionRenderer(option, state);
        }


        const className = clsx({'locked': option.locked, 'disabled': option.disabled})
        const text = renderText(option, textAccessor, valueAccessor, { formatter, pattern });
        let textNode;
        if(reactjs.isElement(text)) {
            const textDescriptor = parseTextElement(text);
            textNode = <Typography style={{fontFamily: textDescriptor.fontFamily}}>{textDescriptor.text}</Typography>;
        } else {
            if(text.length === 0) {
                textNode = <Typography>&nbsp;</Typography>;
            } else {
                textNode = <Typography style={{fontFamily: fontFamily}}>{text}</Typography>
            }
        }

        let icon;
        if(option.locked) {
            icon = <Icon size="small">lock</Icon>
        }
        const cursor = (option.locked || option.disabled) ? 'default' : 'pointer';
        return (
            <div className={className} style={{display: 'flex', alignItems: 'center', cursor: cursor}}  >
                {textNode}
                {icon}
            </div>
        );
    }

    const defaultIsOptionEligible = (option, inputValue) => {
        const text = resolveText(option, props.textAccessor, props.valueAccessor);
        return text.toLowerCase().includes(inputValue?.toLowerCase())
    }

    const isOptionEligible = props.isOptionEligible ? props.isOptionEligible : defaultIsOptionEligible;
    const optionsFilter = (options, state) => {
        return options.filter(option => {
            if(option === '') {
                return false;
            } else if(option.deprecated) {
                return false;
            } else if(lang.isEmpty(state.inputValue)) {
                return true;
            } else {
                return isOptionEligible(option, state.inputValue);
            }
        })
    }

    const renderTitle = selection => {
        const items = lang.isArray(selection) ? [...selection] : [selection];
        const titles =  items.map(item => renderText(item, props.textAccessor, props.valueAccessor, { formatter, pattern }));
        return titles.length > 1 ? titles.join(", ") : titles;
    }

    const classes = useStyles({readOnly, fontFamily});
    if(readOnly) {
        style.pointerEvents = '';
    }

    // Do not set TextField to disabled to hide the placeholder when readOnly is true.
    // TextField has a different appearance when disabled & variant is standard.
    const placeholder = disabled ? null : (i18n.translate(props.placeholder) || undefined);

    useEffect(() => {
        (async () => {
            if(props.code) {
                const codeData = await code.fetch(props.code);
                setCodeData(codeData);
            } else {
                setCodeData([]);
            }
        })();
    }, [props.code]);

    useEffect(() => {
        if(!multiple) {
            const options = resolveOptions(props.options, codeData, valueAccessor, false);
            const selection = mapValueToSelection(props.value, options, valueAccessor, false);
            const any = renderText(selection, textAccessor, valueAccessor, { formatter, pattern });
            const inputDescriptor = reactjs.isElement(any) ? parseTextElement(any) : {text: any};
            setInputDescriptor(inputDescriptor);
        }
    }, [props.value, valueAccessor, textAccessor, props.options, multiple, formatter, pattern, codeData]);

    const selection = mapValueToSelection(props.value, options, props.valueAccessor, multiple);
    const title = renderTitle(selection);

    let ariaLabel;
    if(props["aria-label"]) {
        ariaLabel = i18n.translate(props["aria-label"]);
    } else {
        ariaLabel = i18n.translate(lang.isString(props.label) ? props.label : '');
    }

    return (
        <Box className={clsx("appfuse-selectField", props.className)} style={style} disabled={disabled} fullWidth={fullWidth}>
            <Autocomplete
                title={title}
                aria-label={ariaLabel}
                classes={classes}
                multiple={multiple}
                name={name} value={selection} onChange={handleChange}
                options={options}
                // 指定 renderOption 時，getOptionLabel 就沒有作用。
                getOptionLabel={getOptionLabel}
                renderOption={renderOption}
                getOptionSelected={isOptionSelected}
                renderTags={tagsRenderer}
                filterOptions={optionsFilter}
                fullWidth={fullWidth}
                inputValue={inputDescriptor.text}
                onInputChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                clearOnBlur={false}
                freeSolo={freeSolo}
                forcePopupIcon={forcePopupIcon}
                disableCloseOnSelect={disableCloseOnSelect}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField {...params} required={required} variant={variant} disabled={disabled}
                               label={label} aria-label={ariaLabel} placeholder={placeholder} helperText={helperText} inputProps={{...params.inputProps, "aria-label": ariaLabel}}
                               error={error} focused={focused} margin={margin} size={size} InputProps={{...params.InputProps, readOnly}}/>
                )}
                open={open}
                onOpen={() => !readOnly && setOpen(true)}
                onClose={() => setOpen(false)}
            />
        </Box>
    )
}

function format(value, formatter, pattern) {
    let content;

    if(lang.isNullOrUndefined(formatter)) {
        if(lang.isNumber(value)) {
            formatter = numeral;
        } else if(lang.isDate(value)) {
            formatter = time;
        }
    }

    if(lang.isFunction(formatter)) {
        // use the custom formatter first
        content = formatter(value, pattern);
    } else if(lang.isArray(value)) {
        content = value.reduce((memo, item) => {
            const text = format(item, formatter, pattern);
            return `${memo}, ${text}`;
        }, '');
        content.length > 0 && (content = content.substring(2));
    } else if(lang.isObject(formatter)) {
        content = formatter.format(value, pattern);
    } else {
        content = value?.toString();
    }
    return content;
}

SelectField.propTypes = {
    value: PropTypes.any,
    multiple: PropTypes.bool,
    options: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.func
    ]),
    valueAccessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    textAccessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    helperText: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
    size: PropTypes.oneOf(['small', 'medium']),
    fullWidth: PropTypes.bool,
    margin: PropTypes.oneOf(['dense', 'none', 'normal']),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
    focused: PropTypes.bool,
    freeSolo: PropTypes.bool,
    forcePopupIcon: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    formatter: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func
    ]),
    pattern: PropTypes.string,
    code: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    "aria-label": PropTypes.string,
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    // Tag 文字的最大長度
    maxTagLength: PropTypes.number,
    optionRenderer: PropTypes.func,
    isOptionEligible: PropTypes.func,
    fontFamily: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
}

SelectField.defaultProps = {
    valueAccessor: DEFAULT_VALUE_ACCESSOR,
    textAccessor: DEFAULT_TEXT_ACCESSOR,
    variant: 'standard',
    size: 'medium',
    margin: 'none',
    maxTagLength: 24,
    fontFamily: 'inherit'
}

export default SelectField;