const cropDrafts = [
    {
        "id":"1",
        "applicant":"王小農",
        "category":"crop",
        "type":"initial",
        "createdOn":"2021-05-12T08:20:10.000Z",
        "totalImplementationArea":1.1678,
        "fieldLands":[
            {
                "id":"1",
                "fieldNo":"F01",
                "city":"64",
                "district":"美濃區",
                "landLot":"廣林段",
                "landSerialNo":"249",
                "actualCultivatedArea":0.2968
            },
            {
                "id":"2",
                "fieldNo":"F02",
                "city":"64",
                "district":"美濃區",
                "landLot":"廣林段",
                "landSerialNo":"246",
                "actualCultivatedArea":0.0305
            },
            {
                "id":"3",
                "fieldNo":"F03",
                "city":"64",
                "district":"美濃區",
                "landLot":"廣林段",
                "landSerialNo":"218",
                "actualCultivatedArea":0
            },
            {
                "id":"4",
                "fieldNo":"F04",
                "city":"64",
                "district":"美濃區",
                "landLot":"廣林段",
                "landSerialNo":"277",
                "actualCultivatedArea":0.0719
            },
            {
                "id":"5",
                "fieldNo":"F05",
                "city":"64",
                "district":"美濃區",
                "landLot":"廣林段",
                "landSerialNo":"278",
                "actualCultivatedArea":0.1417
            },
            {
                "id":"6",
                "fieldNo":"F06",
                "city":"64",
                "district":"美濃區",
                "landLot":"廣林段",
                "landSerialNo":"346",
                "actualCultivatedArea":0.3405
            },
            {
                "id":"7",
                "fieldNo":"F07",
                "city":"64",
                "district":"美濃區",
                "landLot":"廣林段",
                "landSerialNo":"329",
                "actualCultivatedArea":0.2864
            }
        ],
        "fieldCrops":[
            {
                "fieldNos":["F01","F02"],
                "crops":"包心白菜、結球萵苣",
                "variety":"高雄149",
                "estimatedProductionPeriods":["1月","11月","12月"],
                "plantingMethod":"seed",
                "seedSources":["selfSeeding"]
            },
            {
                "fieldNos":["F03","F04","F05"],
                "crops":"蘿蔔、胡蘿蔔",
                "variety":"白玉蘿蔔",
                "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                "plantingMethod":"seedling",
                "seedSources":["selfSeeding","outsourcing"]
            },
            {
                "fieldNos":["F03"],
                "crops":"地瓜",
                "variety":"台農 157",
                "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                "plantingMethod":"seed",
                "seedSources":["selfSeeding"]
            }
        ]
    },
    {
        "id":"2",
        "applicant":"王小農",
        "category":"crop",
        "type":"regular",
        "createdOn":"2021-05-12T08:20:10.000Z",
    },
    {
        "id":"3",
        "applicant":"王小農",
        "category":"crop",
        "type":"reEvaluation",
        "createdOn":"2021-05-12T08:20:10.000Z",
    }
]

export default cropDrafts;