const markApplication = [
    {
        "id": "1",
        "farmer": "王森林",
        "applyDate": "2019-05-06T08:20:10.000Z",
        "contactPerson": "王森林",
        "contactPersonPhone": "0911234098",
        "receiveType": "byYourself",
        "useDateStart": "2019-05-06T08:20:10.000Z",
        "useDateEnd": "2022-05-06T08:20:10.000Z",
        "applicationItem": ["玉米", "玉米筍"],
        "style": "sticker",
        "bigMarkQuantity": "1000",
        "smallMarkQuantity": "100",
        "applicationItemDetail": [
            {
                "crop": "玉米",
                "content": [
                    {
                        "spec": "5公斤/袋",
                        "packagingLabel": [
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "玉米袋裝內包裝.pdf",
                                "lastModified": 0
                            },
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "玉米袋裝外包裝.pdf",
                                "lastModified": 0
                            }
                        ]
                    },
                    {
                        "spec": "20公斤/箱",
                        "packagingLabel": [
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "玉米箱裝包裝.pdf",
                                "lastModified": 0
                            }
                        ]
                    }
                ]
            },
            {
                "crop": "玉米筍",
                "content": [
                    {
                        "spec": "10公斤/盒",
                        "packagingLabel": [
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "玉米筍包裝.pdf",
                                "lastModified": 0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "2",
        "farmer": "王森林",
        "applyDate": "2020-01-20T08:20:10.000Z",
        "contactPerson": "王森林",
        "contactPersonPhone": "0911234098",
        "receiveType": "byYourself",
        "inventory": 500,
        "applicationItem": ["玉米", "玉米筍"],
        "style": "sticker",
        "smallMarkQuantity": "200",
        "applicationItemDetail": [
            {
                "crop": "玉米",
                "content": [
                    {
                        "spec": "10公斤/袋",
                    },
                    {
                        "spec": "5公斤/袋",
                        "packagingLabel": [
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "玉米袋裝內包裝.pdf",
                                "lastModified": 0
                            },
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "玉米袋裝外包裝.pdf",
                                "lastModified": 0
                            }
                        ]
                    }
                ]
            },
            {
                "crop": "玉米筍",
                "content": [
                    {
                        "spec": "1公斤/袋",
                        "packagingLabel": [
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "玉米筍包裝.pdf",
                                "lastModified": 0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "3",
        "farmer": "王小保",
        "applyDate": "2020-09-22T08:20:10.000Z",
        "contactPerson": "王小保",
        "contactPersonPhone": "0919234098",
        "receiveType": "byPost",
        "mailingAddress": {
            "city":"63",
            "district":"中正區",
            "street":"忠孝東路",
            "zipCode":"100"
        },
        "inventory": 300,
        "useDateStart": "2020-09-22T08:20:10.000Z",
        "useDateEnd": "2021-09-22T08:20:10.000Z",
        "applicationItem": ["蘿蔔", "地瓜"],
        "style": "sticker",
        "bigMarkQuantity": "1000",
        "smallMarkQuantity": "500",
        "applicationItemDetail": [
            {
                "crop": "蘿蔔",
                "content": [
                    {
                        "spec": "5公斤/袋"
                    }
                ]
            },
            {
                "crop": "地瓜",
                "content": [
                    {
                        "spec": "10公斤/盒",
                        "packagingLabel": [
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "地瓜包裝1.png",
                                "lastModified": 0
                            }
                        ]
                    },
                    {
                        "spec": "15公斤/盒",
                        "packagingLabel": [
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "地瓜包裝2.png",
                                "lastModified": 0
                            }
                        ]
                    },
                    {
                        "spec": "20公斤/盒",
                        "packagingLabel": [
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "地瓜包裝3.png",
                                "lastModified": 0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "4",
        "farmer": "王小保",
        "applyDate": "2021-03-05T08:20:10.000Z",
        "contactPerson": "王小保",
        "contactPersonPhone": "0919234098",
        "receiveType": "byPost",
        "mailingAddress": {
            "city":"63",
            "district":"中正區",
            "street":"忠孝東路",
            "zipCode":"100"
        },
        "inventory": 450,
        "applicationItem": ["蘿蔔", "地瓜"],
        "style": "overprint",
        "applicationItemDetail": [
            {
                "crop": "蘿蔔",
                "content": [
                    {
                        "spec": "5公斤/盒"
                    }
                ]
            },
            {
                "crop": "地瓜",
                "content": [
                    {
                        "spec": "20公斤/箱",
                        "packagingLabel": [
                            {
                                "type": "",
                                "size": 0,
                                "bytes": "",
                                "name": "地瓜包裝.png",
                                "lastModified": 0
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export default markApplication;