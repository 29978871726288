import {Avatar, Box, Divider, makeStyles, Paper, Popover, Typography} from "@material-ui/core";
import Button from "../../lib/appfuse-react/component/Button";
import React from "react";
import PropTypes from 'prop-types';
import useRouter from "../../lib/appfuse-react/hook/useRouter";
import {useHistory} from "react-router-dom";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    avatar: {
        width: '2.4rem',
        height: '2.4rem',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

function MailDetail(props) {
    const classes = useStyles();
    const {anchorEl, open, userInfo, inboxCounts} = props;
    const {cropInboxNumber = 0, recognizedInboxNumber = 0} = inboxCounts;
    const history = useHistory();

    return (
        <Popover anchorEl={anchorEl} open={open}
                 onClose={props.onClose}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'center',
                 }}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}>
            <Paper className={classes.root}>
                <Typography align="center">收件匣</Typography>
                <Box marginY={1} ><Divider /></Box>
                <div className={classes.buttons}>
                    <Badge badgeContent={cropInboxNumber} color="error">
                        <Button icon={"grass"} text={"Inbox"} onClick={()=>{history.push("/Inbox")}} badge={cropInboxNumber} variant={"text"} fullWidth color="primary" />
                    </Badge>
                    <Badge badgeContent={recognizedInboxNumber} color="error">
                        <Button icon={"settings"} text={"Inbox Proto"} onClick={()=>{history.push("/InboxProto")}} badge={recognizedInboxNumber} variant={"text"} fullWidth color="primary" />
                    </Badge>
                </div>
            </Paper>
        </Popover>
    )
}

MailDetail.propTypes = {
    anchorEl: PropTypes.instanceOf(Element),
    open: PropTypes.bool,
    userInfo: PropTypes.object.isRequired,
    inboxCounts: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired
}


export default MailDetail;