import Form from "../../lib/appfuse-react/component/Form";
import {Checkbox, FormControlLabel, FormGroup, Grid, Typography} from "@material-ui/core";
import React, {Fragment, useEffect} from "react";
import i18n from "../../lib/appfuse-react/core/i18n";
import Box from "../../lib/appfuse-react/component/Box";
import Button from "../../lib/appfuse-react/component/Button";
import DateField from "../../lib/appfuse-react/component/DateField";
import SelectField from "../../lib/appfuse-react/component/SelectField";
import UserEvent from "../../lib/appfuse-react/core/UserEvent";
import valueChange from "../Judge/ValueChange";
import {useDispatch, useSelector} from "../../store";
import {selectCounty, setCounty} from "../../reducer/util/utilReducer";
import lang from "../../lib/appfuse-react/core/lang";
import TextField from "../../lib/appfuse-react/component/TextField";
import utilService from "../../service/util/utilService";

function Applicant(props) {
    const {data={}, change={}, readOnly, closeButton=null} = props;

    const handleChange = event => {
        const value = {...data, applicantInfo:event.value};
        const newChange = {...change, applicantInfo:event.change}
        props.onChange(new UserEvent({value, change:newChange}));
    };

    return (
        <Box display="flex" flexDirection="column" fullHeight fullWidth overflow="hidden" >
            <Box marginBottom={2}>
                <Typography variant="h6">{i18n.translate('Applicant Info')}</Typography>
            </Box>

            <Box fullWidth fullHeight overflow="auto" paddingTop={2}>
                <Form value={data.applicantInfo} change={change.applicantInfo} onChange={handleChange} readOnly={readOnly}>
                    <Grid container spacing={2} item xs={12}>
                        {/*申請人姓名*/}
                        <Grid item xs={3}>
                            <TextField name="name" label="Applicant" variant="outlined" fullWidth required/>
                        </Grid>
                        {/*身分證字號*/}
                        <Grid item xs={3}>
                            <TextField name="idNo" label="ID No." variant="outlined" fullWidth required disabled/>
                        </Grid>
                        {/*農場/加工廠名*/}
                        <Grid item xs={3}>
                            <TextField name="farmName" label="Farm Processing Plant Name" variant="outlined" fullWidth required
                                       helperText="If there is no farm name, please fill in the name of the applicant"/>
                        </Grid>
                        {/*申請者類型*/}
                        <Grid item xs={3}>
                            <SelectField name="applicantType" label="Applicant Type" variant="outlined" code='applicantType' fullWidth required />
                        </Grid>
                        {/*性別*/}
                        <Grid item xs={3}>
                            <SelectField name="gender" label="Gender" variant="outlined" code='gender' fullWidth required />
                        </Grid>
                        {/*生日*/}
                        <Grid item xs={3}>
                            <DateField name='birthday' label='Birthday' variant='outlined' fullWidth required/>
                        </Grid>
                        {/*統一編號*/}
                        <Grid item xs={3}>
                            {
                                valueChange.isEqual(data?.applicantInfo?.applicantType, change?.applicantInfo?.applicantType, 'legalPerson') &&
                                <TextField name="taxIdNo" label="Tax Id No" variant="outlined" fullWidth/>
                            }
                        </Grid>
                        {/*公司登記立案日*/}
                        <Grid item xs={3}>
                            {
                                valueChange.isEqual(data?.applicantInfo?.applicantType, change?.applicantInfo?.applicantType, 'legalPerson') &&
                                <DateField name="companyRegistrationDate" label="Company Registration Date" variant="outlined" fullWidth/>
                            }
                        </Grid>
                        {/*手機*/}
                        <Grid item xs={3}>
                            <TextField name="mobilePhone" label="Mobile Phone" variant="outlined" fullWidth required disabled/>
                        </Grid>
                        {/*電話*/}
                        <Grid item xs={3}>
                            <TextField name="phone" label="Phone" variant="outlined" fullWidth/>
                        </Grid>
                        {/*傳真*/}
                        <Grid item xs={3}>
                            <TextField name="fax" label="Fax" variant="outlined" fullWidth />
                        </Grid>
                        {/*電子郵件*/}
                        <Grid item xs={3}>
                            <TextField name="email" label="email" variant="outlined" fullWidth />
                        </Grid>
                        {/*其他聯絡人*/}
                        <Grid item xs={3}>
                            <TextField name="contactPerson" label="Other Contact Person" variant="outlined" fullWidth/>
                        </Grid>
                        {/*聯絡人電話*/}
                        <Grid item xs={3}>
                            <TextField name="contactPersonPhone" label="Contact Person Phone" variant="outlined" fullWidth/>
                        </Grid>
                        <Grid item xs={6}/>
                        {/*戶籍地址/登記立案地址*/}
                        <Grid item xs={12}>
                            <Address name="permanentAddress" subtitle="Permanent Address" required/>
                        </Grid>
                        {/*通訊地址*/}
                        <Grid item xs={12}>
                            <MailingAddress  name="correspondenceAddress" subtitle="Mailing Address" required
                                             sameValue={{...data?.applicantInfo?.permanentAddress, ...change?.applicantInfo?.permanentAddress}}/>
                        </Grid>
                        {/*申請初級加工*/}
                        <Grid  item xs={3}>
                            <FormGroup row>
                                <FormControlLabel name="applyPrimaryProcessing" control={<Checkbox />} label="Apply Primary Processing" />
                            </FormGroup>
                        </Grid>
                        {/*申請認可加工*/}
                        <Grid  item xs={3}>
                            <FormGroup row>
                                <FormControlLabel name="applyApprovedProcessing" control={<Checkbox />} label="Apply Approved Processing" />
                            </FormGroup>
                        </Grid>
                        {/*申請標的*/}
                        <Grid item xs={3}>
                            <SelectField name="conservationTarget" label="Apply Target" variant="outlined" code='conservationTarget' fullWidth required />
                        </Grid>
                        <Grid item xs={3}>
                            {
                                valueChange.isInclude(data?.applicantInfo?.conservationTarget, change?.applicantInfo?.conservationTarget, 'species') &&
                                <TextField name="species" label="Species Name" fullWidth variant="outlined" required/>
                            }
                        </Grid>
                        {
                            data?.applicantInfo?.cropOrganic && (
                                <Grid item xs={12}>
                                    {/*作物有機證書資訊*/}
                                    <OrganicQualificationContent name='cropOrganicInfo' title='Crop Organic Info'/>
                                </Grid>
                            )
                        }
                        {
                            data?.applicantInfo?.processingOrganic && (
                                <Grid item xs={12}>
                                    {/*加工有機證書資訊*/}
                                    <OrganicQualificationContent name='processingOrganicInfo' title='Processing Organic Info'/>
                                </Grid>
                            )
                        }
                    </Grid>
                </Form>
            </Box>

            <Box display="flex" justifyContent="space-between" marginTop={2} marginBottom={2}>
                <Box display="flex" gap={1}>
                    {closeButton}
                </Box>
                <Button text="Next" variant="contained" color="primary" onClick={props.onNext}/>
            </Box>
        </Box>
    );
}

function MailingAddress(props){
    const {subtitle, value={}, required, sameValue={}, readOnly} = props;

    const addressData = ['country', 'county', 'town', 'street', 'zip'];
    const copyValue = () => {
        let address = {sameAsCompanyAddress:true};
        addressData.forEach(name => address[name] = sameValue[name]);
        return address;
    };

    if(value?.sameAsCompanyAddress) {
        const notSame = addressData.some(name => value[name]!==sameValue[name]);
        if(notSame) {
            const value = copyValue();
            props.onChange(new UserEvent({value}));
        }
    }

    const handleSameAsCompanyAddressChange = (event) => {
        const {change} = event;
        const {sameAsCompanyAddress} = change;
        if(sameAsCompanyAddress) {
            const value = copyValue();
            props.onChange(new UserEvent({value}));
        } else {
            props.onChange(new UserEvent({value: {...value, sameAsCompanyAddress:false}}));
        }
    };

    const handleCorrespondenceAddressChange = (event) => {
        const {value} = event;
        props.onChange(new UserEvent({value: {...value, sameAsCompanyAddress:false}}));
    };

    return (
        <Box display="column">
            <Box display="flex"  alignItems="center" gap={2} marginBottom={-1}>
                <Typography variant="subtitle1" gutterBottom color="textSecondary">
                    {i18n.translate('Mailing Address')}
                </Typography>
                <Form value={value} onChange={handleSameAsCompanyAddressChange} readOnly={readOnly}>
                    <FormGroup row>
                        <FormControlLabel name="sameAsCompanyAddress" control={<Checkbox />} label={i18n.translate('Same As Permanent Address')} />
                    </FormGroup>
                </Form>
            </Box>
            <Address name="correspondenceAddress" withTitle={false} subtitle={subtitle} value={value}
                     onChange={handleCorrespondenceAddressChange} required={required} readOnly={readOnly}/>
        </Box>
    )
}

function Address(props){
    const {subtitle, value={}, withTitle=true, required, readOnly} = props;
    const county = useSelector(selectCounty);
    const {towns=[]} = county.find(item => item.code===value.county) || {};
    const keys = ['county', 'town', 'zip', 'street'];
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if(lang.isEmpty(county)) {
                const data = await utilService.findCounty();
                dispatch(setCounty(data));
            }
        })()
    }, []);

    const handleChange = ({value, change:{county, town}}) => {
        if(lang.isNotUndefined(county)) {
            value.town = null;
            value.zip = null;
        }
        if(lang.isNotUndefined(town)) {
            value.zip = towns.find(item => item.code===value.town)?.zip;
        }
        const notEmpty = keys.some(key => lang.isNotEmpty(value[key]));
        if(notEmpty) value.country = 'TW';
        else value.country = null;
        props.onChange(new UserEvent({value}));
    };

    const variant = readOnly ? 'standard' : 'outlined';

    return (
        <Form value={value} onChange={handleChange} readOnly={readOnly}>
            {withTitle &&
            <Typography variant="subtitle1" gutterBottom color="textSecondary">
                {i18n.translate(subtitle)}
            </Typography>
            }
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <SelectField name="county" label='City' variant={variant} options={county} valueAccessor='code' textAccessor='name' required={required} />
                </Grid>
                <Grid item xs={3}>
                    <SelectField name="town" label='District' variant={variant} options={towns} valueAccessor='code' textAccessor='name' fullWidth required={required} />
                </Grid>
                <Grid item xs={4} >
                    <TextField name="street" label='Street' variant={variant} fullWidth required={required} />
                </Grid>
                <Grid item xs={2}>
                    <TextField name="zip" label='ZIP' variant={variant} fullWidth />
                </Grid>
            </Grid>
        </Form>
    );
}

function OrganicQualificationContent(props) {
    const {value={}, title='', readOnly} = props;

    return (
        <Fragment>
            <Typography variant='subtitle1' gutterBottom color="textSecondary">{i18n.translate(title)}</Typography>
            <Form value={value} onChange={props.onChange} readOnly={readOnly}>
                <Grid container spacing={2} item xs={12}>
                    {/*有機驗證單位*/}
                    <Grid item xs={3}>
                        <SelectField name="certificationBody" label="Organic Certification Body" variant='outlined' code='certificationBody' fullWidth/>
                    </Grid>
                    {
                        valueChange.isEqualCmp(value.certificationBody, 'others') &&
                        <Grid item xs={9}>
                            <TextField name="certificationBodyDesc" label="Others, please specify" variant='outlined' fullWidth />
                        </Grid>
                    }
                    {/*有機有效日期*/}
                    <Grid item xs={3}>
                        <DateField name="organicValidDate" label="Organic Valid Date" variant='outlined' fullWidth />
                    </Grid>
                    {/*有機證書字號*/}
                    <Grid item xs={3}>
                        <TextField name="organicCertificateNo" label="Organic Certificate No" variant='outlined' fullWidth />
                    </Grid>
                </Grid>
            </Form>
        </Fragment>
    );
}

export default Applicant;

export {Address, MailingAddress};