import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "../../store";
import React, {useEffect, useState} from "react";
import {withExplorer} from "../../layout/Explorer";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Form from "../../lib/appfuse-react/component/Form";
import {Checkbox, Divider, FormControlLabel, FormGroup, Grid, useTheme} from "@material-ui/core";
import Button from "../../lib/appfuse-react/component/Button";
import Box from "../../lib/appfuse-react/component/Box";
import i18n from "../../lib/appfuse-react/core/i18n";
import usePrompt from "../../lib/appfuse-react/hook/usePrompt";
import UserEvent from "../../lib/appfuse-react/core/UserEvent";
import registrationService from "../../service/iam/registrationService";
import {selectRegistrationForm, setRegistrationForm} from "../../reducer/iam/registrationReducer";
import useRouter from "../../lib/appfuse-react/hook/useRouter";
import Paper from "../../lib/appfuse-react/component/Paper";
import Space from "../../lib/appfuse-react/component/Space";
import SelectField from "../../lib/appfuse-react/component/SelectField";
import DateField from "../../lib/appfuse-react/component/DateField";
import utilService from "../../service/util/utilService";
import CircularProgress from "../../lib/appfuse-react/component/CircularProgress";
import {selectCounty, setCounty} from "../../reducer/util/utilReducer";
import lang from "../../lib/appfuse-react/core/lang";
import TextField from "../../lib/appfuse-react/component/TextField";
import {Address} from "../../component/Application/Applicant";

const useStyles = makeStyles((theme) => ({
    transparent: {
        backgroundColor: 'transparent'
    },
    whiteSpacePreWrap:{
        whiteSpace:'pre-wrap'
    },
}));

const STEPS = ['Registration Description', 'Basic Information', 'Applied Scope - Crop', "Applied Scope - Processing", 'Verify your mobile phone'];
const FORMS = [RegistrationForm_0, RegistrationForm_1, RegistrationForm_2, RegistrationForm_3, RegistrationForm_4];

function getStepContent(step) {
    switch (step) {
        case 0:
            return `Notes on registration`;
        case 1:
            return `Please enter your basic information to start to create your account.`;
        case 2:
            return 'Please provide us with your crop information for evaluation.';
        case 3:
            return 'Please provide us with your crop information for evaluation.';
        case 4:
            return `Click the send button to get the verification code, then enter the verification code sent to your mobile phone and click the verify button.`;
        default:
            return 'Unknown step';
    }
}

function Registration(props) {
    const [activeStep, setActiveStep] = useState(0);
    const [nowSteps, setNowSteps] = useState(STEPS);
    const value = useSelector(selectRegistrationForm);
    const dispatch = useDispatch();
    const router = useRouter();
    const prompt = usePrompt();

    const county = useSelector(selectCounty);

    useEffect(() => {
        calculateNowSteps(value);
        (async () => {
            if(lang.isEmpty(county)) {
                const data = await utilService.findCounty();
                dispatch(setCounty(data));
            }
        })()
    }, []);

    const handleChange = (event) => {
        const {change={}, value} = event;
        if(change.farmCounty) {
            value.farmTown = null
        }
        if(change.applyCategory) {
            calculateNowSteps(value);
        }
        dispatch(setRegistrationForm(value));
    };

    const calculateNowSteps = (value) => {
        const {applyCategory = []} = value || {};
        if (lang.isNotNullOrUndefined(applyCategory)) {
            const crop = applyCategory.find(item => item === 'crop');
            const recognizedProcessing = applyCategory.find(item => item === 'recognizedProcessing');
            const steps = [...STEPS];
            if (!crop) {
                steps.splice(2, 1);
            }
            if (!recognizedProcessing && !crop) {
                steps.splice(2, 1);
            } else if (!recognizedProcessing && crop) {
                steps.splice(3, 1);
            }
            setNowSteps(steps);
        } else {
            setNowSteps(STEPS);
        }
    }

    const calculateNextStep = () => {
        const {applyCategory = []} = value || {};
        if (lang.isNotNullOrUndefined(applyCategory)) {
            const crop = applyCategory.find(item => item === 'crop');
            const recognizedProcessing = applyCategory.find(item => item === 'recognizedProcessing');
            const nextStep = activeStep + 1;
            if (nextStep === 2 && !crop) {
                if (recognizedProcessing) {
                    setActiveStep(nextStep + 1);
                } else {
                    setActiveStep(nextStep + 2);
                }
            } else if (nextStep === 3 && !recognizedProcessing) {
                setActiveStep(nextStep + 1);
            } else {
                setActiveStep(nextStep);
            }
        } else {
            setActiveStep((prevState) => (prevState + 1))
        }
    }

    const calculatePreviousStep = () => {
        const {applyCategory = []} = value || {};
        if (lang.isNotNullOrUndefined(applyCategory)) {
            const crop = applyCategory.find(item => item === 'crop');
            const recognizedProcessing = applyCategory.find(item => item === 'recognizedProcessing');
            const nextStep = activeStep - 1;
            if (nextStep === 2 && !crop) {
                setActiveStep(nextStep - 1);
            } else if (nextStep === 3 && !recognizedProcessing) {
                if (!crop) {
                    setActiveStep(nextStep - 2);
                } else {
                    setActiveStep(nextStep - 1);
                }
            } else {
                setActiveStep(nextStep);
            }
        } else {
            setActiveStep((prevState) => (prevState - 1))
        }
    }

    const handleNext = async (value) => {
        try {
            await registrationService.validate(value);
            calculateNextStep();
        } catch (e) {
            prompt.alert(e, {title:'Invalid input.'});
        }
    };

    const handleBack = () => {
        calculatePreviousStep();
    };

    const handleComplete = async () => {
        try {
            await registrationService.registration(value);
            dispatch(setRegistrationForm({}));
            prompt.confirm('You Have Successfully Registered.', {
                title: 'Welcome to GCDMP Web',
                onAction: () => router.login()
            });
        } catch (e) {
            prompt.alert(e, {title: 'Registration failed'});
        }
    }

    const title = STEPS[activeStep];
    const Form = FORMS[activeStep];

    return (
        <Box fullHeight fullWidth backgroundColor="black" backgroundOpacity={0.4}
             display="flex" justifyContent="center" alignItems="center" >
            <Box display="flex" width="80%" height="80%" gap={4} overflow="hidden" >
                <Paper fullWidth fullHeight overflow="hidden" backgroundOpacity={0.95}>
                    <Form value={value} onChange={handleChange} title={title} activeStep={activeStep}
                          onNext={handleNext} onBack={handleBack}
                          onComplete={handleComplete} />
                </Paper>
                <Paper minWidth="20rem" backgroundOpacity={0.95} fullHeight overflow="auto" >
                    <RegistrationStepper activeStep={activeStep} nowSteps={nowSteps} />
                </Paper>
            </Box>
        </Box>
    );
}

function RegistrationForm_0(props){
    const theme = useTheme();
    const router = useRouter();
    const handleSignInInstead = () => router.login();

    return (
        <Box display="flex" flexDirection="column" fullWidth fullHeight overflow="hidden">
            <Box backgroundColor={theme.palette.secondary.main}  paddingX={3} paddingY={2} borderBottom="1px solid grey">
                <Typography variant="h5">{i18n.translate('Green Conservation Application Willingness Questionnaire')}</Typography>
            </Box>
            <Box padding={3} display="flex" flexDirection="column" gap={1} fullHeight overflow="auto">
                <Typography>{i18n.translate('Questionnaire description 1')}</Typography>
                <Typography>{i18n.translate('Questionnaire description 2')}</Typography>
                <Typography>{i18n.translate('Questionnaire description 3')}</Typography>
                <Box display="flex" justifyContent="space-between" marginTop={2}>
                    <Button text="Sign in instead" color="secondary" onClick={handleSignInInstead} />
                    <Button text="Start registration" variant="contained" color="primary" onClick={() => props.onNext({})} />
                </Box>
            </Box>
        </Box>
    )
}

function RegistrationForm_1(props) {
    const {title, activeStep} = props;
    const validateValue = {
        applicant: props.value?.applicant || null,
        idNo: props.value?.idNo || null,
        farmName: props.value?.farmName || null,
        mobilePhone: props.value?.mobilePhone || null,
        phone: props.value?.phone || null,
        fax: props.value?.fax || null,
        email: props.value?.email || null,
        applyCategory: props.value?.applyCategory || null,
    };

    return (
        <Box display="flex" flexDirection="column" fullWidth fullHeight padding={3} gap={2} overflow="hidden">
            <Typography variant="h5">{i18n.translate(title)}</Typography>
            <Typography color="textSecondary">{i18n.translate(getStepContent(activeStep))}</Typography>
            <Divider />
            <Form value={props.value} onChange={props.onChange} fullWidth fullHeight flexGrow={1} overflow="auto" paddingTop={1}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField name="applicant" label="Applicant" variant="outlined"
                                   fullWidth required/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name="idNo" label="Id No." variant="outlined"
                                   fullWidth required />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name="farmName" label="Farm Processing Plant Name" variant="outlined" required
                                   fullWidth helperText="If there is no farm name, please fill in the name of the applicant" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name="mobilePhone" label="Mobile Phone" variant="outlined"
                                   fullWidth required/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name="phone" label="Phone" variant="outlined"
                                   fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name="fax" label="Fax" variant="outlined"
                                   fullWidth  />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField name="email" label="Email" variant="outlined"
                                   fullWidth  />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectField name="applyCategory" label="Applied Item" variant="outlined" fullWidth multiple required code='applyCategory'/>
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="space-between" marginTop={2}>
                    <Button text="Back" color="secondary" onClick={props.onBack} />
                    <Button text="Next" variant="contained" color="primary" onClick={() => props.onNext(validateValue)} />
                </Box>
            </Form>
        </Box>
    )
}

function RegistrationForm_2(props) {
    const {title, activeStep} = props;
    const {certificationBody} = props.value;
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);
    const county = useSelector(selectCounty);
    const towns = county.find(item => item.code===props.value?.farmCounty)?.towns;
    const validateValue = {
        farmCounty: props.value?.farmCounty || null,
        farmTown: props.value?.farmTown || null,
        area: props.value?.area || null,
        mainCrop: props.value?.mainCrop || null,
        productionPeriod: props.value?.productionPeriod || null,
        understandClosedGreenhouseNotAccept: props.value?.understandClosedGreenhouseNotAccept || false,
        certificationBody: props.value?.certificationBody || null,
        certificationBodyDesc: props.value?.certificationBodyDesc || null,
        organicCategories: props.value?.organicCategories || null,
        organicValidDate: props.value?.organicValidDate || null,
        organicCertificateNo: props.value?.organicCertificateNo || null,
        assistantAndBriefDate: props.value?.assistantAndBriefDate || null,
    };

    useEffect(() => {
        (async () => {
            if(lang.isEmpty(county)) {
                const county = await utilService.findCounty();
                dispatch(setCounty(county));
            }
            setReady(true);
        })();
    }, []);

    return (
        <Box display="flex" flexDirection="column" fullWidth fullHeight padding={3} gap={2} overflow="hidden">
            <Typography variant="h5">{i18n.translate(title)}</Typography>
            <Typography color="textSecondary">{i18n.translate(getStepContent(activeStep))}</Typography>
            <Divider />
            <CircularProgress value={ready}>
                <Form value={props.value} onChange={props.onChange} fullWidth fullHeight overflow="auto" paddingTop={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SelectField name="farmCounty" label="City (Farm location)" variant="outlined" fullWidth
                                         options={county} valueAccessor='code' textAccessor='name' required/>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField name="farmTown" label="District (Farm location)" variant="outlined" fullWidth
                                         options={towns} valueAccessor='code' textAccessor='name'/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField name="area" label="Field Area (hectare)"  variant="outlined" fullWidth type="number"
                                       inputProps={{  step: "0.0001" }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="mainCrop" label="Main Crop"  variant="outlined" fullWidth
                                       multiline required/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="productionPeriod" label="Production Period"  variant="outlined"
                                       fullWidth  multiline required/>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display='flex' fullWidth fullHeight alignContent='center'>
                                <FormControlLabel name="understandClosedGreenhouseNotAccept" control={<Checkbox />}
                                                  label="I understand that enclosed greenhouses are not within the scope of green conservation."/>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <SelectField name="certificationBody" label="Organic Certification Body" variant="outlined" fullWidth code='certificationBody'/>
                        </Grid>
                        <Grid item xs={7}>
                            {certificationBody && certificationBody==='others' && (
                                <TextField name="certificationBodyDesc" label="Others, please specify" fullWidth variant="outlined" />
                            ) }
                        </Grid>
                        <Grid item xs={5}>
                            <SelectField name="organicCategories" label="Organic Category" variant="outlined" fullWidth multiple code='organicCategory' placeholder='Multiple'/>
                        </Grid>
                        <Grid item xs={4}>
                            <DateField name="organicValidDate" label="Organic Valid Date" variant='outlined' fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField name="organicCertificateNo" label="Organic Certificate No"  variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="assistantAndBriefDate" label="Green Conservation Counselor And Briefing Date" variant="outlined"
                                       fullWidth  />
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="space-between" marginTop={2}>
                        <Button text="Back" color="secondary" onClick={props.onBack} />
                        <Button text="Next" variant="contained" color="primary" onClick={() => props.onNext(validateValue)}/>
                    </Box>
                </Form>
            </CircularProgress>
        </Box>
    )
}

{
    const props = {}
    const validateAddress = {
        county: props.value?.county || null,
        town: props.value?.town || null,
        street: props.value?.street || null,
        zip: props.value?.zip || null,
    }
}

function RegistrationForm_3(props) {
    const {foodProcessingAccreditationUnit} = props.value;
    const {title, activeStep} = props;
    const validateValue = {
        confirmApply: props.value?.confirmApply || false,
        processingFactoryName: props.value?.processingFactoryName || null,
        processFactoryAddress: props.value?.processFactoryAddress || null,
        channels: props.value?.channels || null,
        businessPhilosophy: props.value?.businessPhilosophy || null,
        foodProcessingAccreditationUnit: props.value?.foodProcessingAccreditationUnit || null,
        foodProcessingAccreditationUnitDesc: props.value?.foodProcessingAccreditationUnitDesc || null,
        accreditationValidDate: props.value?.accreditationValidDate || null,
    };

    return (
        <Box display="flex" flexDirection="column" fullWidth fullHeight padding={3} gap={2} overflow="hidden">
            <Typography variant="h5">{i18n.translate(title)}</Typography>
            <Typography color="textSecondary">{i18n.translate(getStepContent(activeStep))}</Typography>
            <Divider />
            <Form value={props.value} onChange={props.onChange} fullWidth fullHeight overflow="auto" paddingTop={1}>
                <Grid container spacing={2} style={{overflow: 'hidden'}}>
                    <Grid item xs={12}>
                        <TextField name="processingFactoryName" label="Process Factory Name"  variant="outlined" fullWidth required/>
                    </Grid>
                    <Grid item xs={12}>
                        <Address name='processingFactoryAddress'/>
                    </Grid>
                    <Grid item xs={12}>
                        <SelectField code={'salesChannel'} multiple name="channels" label="Channels"  variant="outlined" fullWidth required/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="businessPhilosophy" label="Business Philosophy"  variant="outlined" fullWidth multiline required/>
                    </Grid>
                    <Grid item xs={5}>
                        <SelectField name="foodProcessingAccreditationUnit" label="Food Processing Accreditation Unit" variant="outlined" fullWidth code='foodProcessingAccreditationUnit'/>
                    </Grid>
                    <Grid item xs={7}>
                        {foodProcessingAccreditationUnit && foodProcessingAccreditationUnit==='others' && (
                            <TextField name="foodProcessingAccreditationUnitDesc" label="Others, please specify" fullWidth variant="outlined" />
                        ) }
                    </Grid>
                    <Grid item xs={4}>
                        <DateField name="accreditationValidDate" label="Accreditation Valid Date" variant='outlined' fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box fullWidth paddingBottom={2}>
                            <Box fullWidth paddingY={1}>
                                <Typography style={{whiteSpace: 'pre-line'}}>
                                    {i18n.translate('confirmApply....')}
                                </Typography>
                                <FormControlLabel name="confirmApply" control={<Checkbox />}
                                                  label="I have read it and agree to abide by the above statement."/>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="space-between" paddingTop={2}>
                    <Button text="Back" color="secondary" onClick={props.onBack} />
                    <Button text="Next" variant="contained" color="primary" onClick={() => props.onNext(validateValue)}/>
                </Box>
            </Form>
        </Box>
    )
}

function RegistrationForm_4(props) {
    const classes = useStyles();
    const { value, title, activeStep } = props;
    const {mobilePhone = '', email = null} = value;
    const prompt = usePrompt();

    const handleRequestToken = async () => {
        try {
            await registrationService.requestToken(mobilePhone, email);
            prompt.info('Open the mobile phone and find the verification code.');
        } catch(e) {
            prompt.alert('Please try again later.', {title:'Failed to send the verification code.'});
        }
    }

    const handleVerifyEmail = async () => {
        try {
            const valid = await registrationService.verifyEmail(mobilePhone, value.token);
            if(valid) {
                props.onChange(new UserEvent({
                    value : {...value, valid: true}
                }));
            }
        } catch(e) {
            prompt.alert('Please try again later.', {title:'Failed to verify the email.'});
        }
    }


    return (
        <Box display="flex" flexDirection="column" fullWidth fullHeight padding={3} gap={2} overflow="hidden">
            <Typography variant="h5">{i18n.translate(title)}</Typography>
            <Typography color="textSecondary">{i18n.translate(getStepContent(activeStep))}</Typography>
            <Divider />
            <Form value={value} onChange={props.onChange} fullWidth fullHeight overflow="auto" flexGrow={1} paddingTop={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Button text="Click to get the verification code." color="primary" onClick={handleRequestToken} />
                        <Button color="primary" icon="send" variant="icon" onClick={handleRequestToken} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" gap={2}>
                            <TextField name="token" label="Verification Code" variant="outlined"
                                       fullWidth size="small"
                                       helperText="Please enter the verification code received by mobile phone"/>
                            <FormGroup>
                                <FormControlLabel disabled name="valid" control={<Checkbox />}/>
                            </FormGroup>
                            <Button color={"secondary"} variant={'contained'} text="Verify" onClick={handleVerifyEmail} />
                        </Box>
                    </Grid>
                </Grid>
                <Space vertical spacing={2} />
                <Typography><div className={classes.whiteSpacePreWrap}>{i18n.translate('Welcome ......')}</div></Typography>
                <Box display="flex" justifyContent="space-between" paddingTop={1}>
                    <Button text="Back" color="secondary" onClick={props.onBack} />
                    <Button text="Complete" variant="contained" color="primary" onClick={props.onComplete}/>
                </Box>
            </Form>
        </Box>
    )
}

function RegistrationStepper(props) {
    const classes = useStyles();
    const {activeStep} = props;

    return (
        <Stepper className={classes.transparent} activeStep={activeStep} orientation="vertical">
            {props.nowSteps.map((label, index) => (
                <Step key={label}>
                    <StepLabel><Typography>{i18n.translate(label)}</Typography></StepLabel>
                    <StepContent>
                        <Typography>{i18n.translate(getStepContent(index))}</Typography>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
}

export default withExplorer(Registration);